import React, { Component } from 'react';
import Graph from './charts/Graph';
import DataPlaceholder from './DataPlaceholder';
import AggregateWindow from './AggregateWindow';

// These (for now) are all the types of graphs that we have to display
const mskGraphs = [
  {
    value: "formOverTime",
    label: "Form Graph"
  },
  {
    value: "classificationConfidence",
    label: "Classification Confidence"
  },
  {
    value: "tripCount",
    label: "Trip Count"
  },
  {
    value: "accDistribution",
    label: "Movement Plane Distribution"
  }
];

const respGraphs = [
  {
    value: "inhalerShake",
    label: "Inhaler Shake Count Distribution"
  }
];

/**
 * Display window for tabular interface
 */
class TabDisplayWindow extends Component {
  constructor(props) {
    super(props);
    /**
     * TabDisplayWindow state
     * @type {Object}
     * @property {number} respData Number of datasets for respiratory tab
     * @property {number} mskData Number of datasets for musculoskeletal tab
     */
    this.state = {
      respData: 0,
      mskData: 0,
    };
  }

  /**
   * Compares provided data length, and recalculates state variables
   * @param {Object} prevProps Previous props
   */
  componentDidUpdate(prevProps) {
    if(prevProps.data.length != this.props.data.length){
      // Count respiratory sets
      let count = 0;
      for (let i=0; i<this.props.data.length; i++) {
        if(this.props.data[i].exercise === 'sumo') count++;
      }
      // Set msk and respiratory counters
      this.setState({...this.state,
                    respData: count,
                    mskData: this.props.data.length - count});
    }
  }

  render() {
    return(
      <div>
        {this.props.tabID === '0' ? <AggregateWindow data={this.props.aggData} pid={this.props.pid}/> : null}
        {this.props.tabID === '1' ? this.state.mskData > 2 ?
          <Graph graphSet={mskGraphs} data={this.props.data} default={"formOverTime"}/> :
            <DataPlaceholder text={"Not enough musculoskeletal data to display graphs"}/>
          : null}
        {this.props.tabID === '2' ? this.state.respData > 2 ?
          <Graph graphSet={respGraphs} data={this.props.data} default={"inhalerShake"}/> : 
            <DataPlaceholder text={"Not enough respiratory data to display graphs"}/>
          : null}
      </div>
    )
  }
}

export default TabDisplayWindow;
