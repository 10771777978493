import React, { Component } from 'react';

export class MSKObject extends Component {
  constructor(props) {
    super(props);
    this.state = {
      render: true,
      data: this.props.data
    };
  };
  render() {
    if(this.state.render) {
      return(
        <div className={"hover:bg-gray-300 border-4 rounded-3xl w-72 p-3 mb-2 " + (this.props.monitorSession ? (this.props.compliant ? "border-green-600": "border-red-700") : "border-blue-700")}>
          <label className="flex justify-center font-bold text-gray-900"> {this.props.firstName} {this.props.lastName} </label>
          <div className="flex justify-center">
            <label className="font-normal text-gray-900"> MRN: </label> 
            <label className="font-semibold text-gray-900"> {this.props.mrn} </label>
          </div>
          <div className="flex justify-center">
            <label className="font-normal text-gray-900"> TUG: </label> 
            <label className="font-semibold text-gray-900"> {this.props.tug} </label>
          </div>
          <div className="flex justify-center">
            <label className="font-normal text-gray-900"> Sit-To-Stand: </label> 
            <label className="font-semibold text-gray-900"> {this.props.sitToStand} </label>
          </div>
          <div className="flex justify-center">
            <label className="font-normal text-gray-900"> Gait Abnormality: </label> 
            <label className="font-semibold text-gray-900"> {this.props.gaitAbnormality} </label>
          </div>
          <div className="flex justify-center">
            <label className="font-normal text-gray-900"> Gait Pathology: </label> 
            <label className="font-semibold text-gray-900"> {this.props.gaitPathology} </label>
          </div>
          <div className="flex justify-center">
            <label className="font-normal text-gray-900"> Gait Asymetry: </label> 
            <label className="font-semibold text-gray-900"> {this.props.gaitAsymetry} </label>
          </div>
          <div className="flex justify-center">
            <label className="font-normal text-gray-900"> Fall Risk: </label> 
            <label className="font-semibold text-gray-900"> {this.props.fallRisk} </label>
          </div>
          <div className="flex justify-center">
            <label className="font-normal text-gray-900"> Steps: </label> 
            <label className="font-semibold text-gray-900"> {this.props.steps} </label>
          </div>
          <div className="flex justify-center">
            <label className="font-normal text-gray-900"> MSK Adherence: </label> 
            <label className="font-semibold text-gray-900"> {this.props.mskAdherence} </label>
          </div>
        </div>
      )
    } else {
      return(null)
    }
  }
}
