import React, { Component } from "react";
import axios from "axios";
import bcrypt from "bcryptjs";
import { withRouter } from 'react-router';
import { SIGNUP_PATH, SIGNUP_TITLE, AUTH_TOKEN_KEY, INFO_KEY, PORTAL_PAGE_PATH, LOGIN_PAGE_PATH } from '../resources/Vault';

/**
 * Component for collecting and validating signup information
 */
class SignupInput extends Component {
  constructor () {
    super();
    /**
     * SignupInput component state
     * @type {Object}
     * @property {string} password1 Password
     * @property {string} password2 Confirm password
     * @property {string} email Email
     * @property {string} firstName First name
     * @property {string} lastName Last name
     * @property {string} org Organization
     * @property {boolean} newOrg Join or Create organization
     * @property {boolean} passwordError Error in password
     * @property {boolean} nameError Error in name
     * @property {boolean} matchError Password mismatch
     */
    this.state = {
      password1: "",
      password2: "",
      email: "",
      firstName: "",
      lastName: "", 
      org: "",
      newOrg: false,
      passwordError: false,
      emailError: false,
      nameError: false,
      matchError: false,
      opacity: false,
    }
    this.onSubmit = this.onSubmit.bind(this);
  }

  /**
   * Checks for errors and submits the backend request
   */
  onSubmit = async () => {
    // If all fields are valid, push request to backend API
    console.log(this.state)
    if ((!this.state.passwordError && !this.state.emailError) && (!this.state.nameError && !this.state.matchError)) {
      this.setState({...this.state, opacity: true});
      let user_info = {
        email: this.state.email,
        firstName: this.state.firstName,
        lastName: this.state.lastName,
        password: bcrypt.hashSync(this.state.password1),
      };

      if (this.state.newOrg){
        user_info['org'] = this.state.org;
      }

      axios
        .post(SIGNUP_PATH, {
          title: SIGNUP_TITLE,
          user_info: user_info,
        })
        .then((response) => {
          this.setState({...this.state, opacity: false});
          localStorage.setItem(AUTH_TOKEN_KEY, response.data.auth_token);
          localStorage.setItem(INFO_KEY, JSON.stringify(response.data.acc));
          this.props.history.push({pathname: PORTAL_PAGE_PATH})
        });
    }
  };

  /**
   * Checks input fields for errors
   */
  checkErrors () {
    let passwordMatch = this.state.password1 === this.state.password2;
    let passwordLength = this.state.password1.length >= 10 && this.state.password1.length <= 32;
    let hasUpper = this.state.password1.toUpperCase() !== this.state.password1;
    let hasLower = this.state.password1.toLowerCase() !== this.state.password1;
    let specialCharacters = new RegExp(/[~`!#$%\^&*+=\-\[\]\\';,/{}|\\":<>\?]/);
    let hasSpecial = specialCharacters.test(this.state.password1);

    if (passwordLength && hasUpper && hasLower && hasSpecial) {
      console.log("passerror false");
      this.setState({...this.state.passwordError = false});
    } else {
      console.log("passerror true");
      this.setState({...this.state.passwordError = true});
    }
    if (this.state.email.length !== 0) {
      this.setState({...this.state.emailError = false});
    } else {
      this.setState({...this.state.emailError = true});
    }
    if (this.state.firstName !== '' && this.state.lastName !== '') {
      
      this.setState({...this.state.nameError = false});
    } else {
      this.setState({...this.state.nameError = true});
    }
    if (passwordMatch) {
      console.log("matchError false");
      this.setState({...this.state.matchError = false});
    } else {
      console.log("matchError true");
      this.setState({...this.state.matchError = true});
    }

    this.onSubmit();
  }
  render () {
    return (
      <>
        <section className={"w-full g-gradient-to-r from-white via-white to-gray-100 "  + (this.state.opacity ? "opacity-50 pointer-events-none" : "opacity-100")}>
          <div className="mx-auto max-w-7xl relative">
            <div className=" w-full absolute g-gradient-to-r from-white via-white to-gray-100"></div>
            <div className="flex flex-row justify-center">
              <div className="w-full bg-green lg:w-6/12 xl:w-5/12">
                <div className="flex flex-col items-start justify-start w-full h-full p-10 lg:p-16 xl:p-24">
                  <h4 className="w-full text-3xl font-bold">Signup</h4>
                  <p className="text-lg text-gray-500">
                    or, if you have an account you can{" "}
                    <a href={ LOGIN_PAGE_PATH } className="text-blue-600 underline" >
                      login
                    </a>
                  </p>
                  <div className="relative w-full mt-10 space-y-8">
                    <div className="flex flex-row items-start space-x-4">
                      <div className="relative">
                        <label className="font-medium text-gray-900">
                          First Name
                        </label>
                        {!this.state.nameError ?  
                          <input
                            onChange={event => this.setState({...this.state,firstName: event.target.value})}
                            type="text"
                            className="block w-full px-4 py-4 mt-2 text-xl placeholder-gray-400 bg-gray-200 rounded-lg focus:outline-none focus:ring-4 focus:ring-blue-600 focus:ring-opacity-50"
                            placeholder="First Name"
                          />
                        :
                          <input
                            onChange={event => this.setState({...this.state,firstName: event.target.value, nameError: false})}
                            type="text"
                            className="block w-full px-4 py-4 mt-2 text-xl placeholder-gray-400 bg-gray-200 rounded-lg ring outline-none ring-4 ring-opacity-50 ring-red-600 focus:outline-none focus:ring-4 focus:ring-blue-600 focus:ring-opacity-50"
                            placeholder="First Name"
                          />
                        }
                      </div>
                      <div className="relative">
                        <label className="font-medium text-gray-900">
                          Last Name
                        </label>
                        {!this.state.nameError ?  
                          <input
                            onChange={event => this.setState({...this.state,lastName: event.target.value})}
                            type="text"
                            className="block w-full px-4 py-4 mt-2 text-xl placeholder-gray-400 bg-gray-200 rounded-lg focus:outline-none focus:ring-4 focus:ring-blue-600 focus:ring-opacity-50"
                            placeholder="Last Name"
                          />
                        :
                          <input
                            onChange={event => this.setState({...this.state,lastName: event.target.value, nameError: false})}
                            type="text"
                            className="block w-full px-4 py-4 mt-2 text-xl placeholder-gray-400 bg-gray-200 rounded-lg ring outline-none ring-4 ring-opacity-50 ring-red-600 focus:outline-none focus:ring-4 focus:ring-blue-600 focus:ring-opacity-50"
                            placeholder="Last Name"
                          />
                        }
                      </div>
                    </div>
                    <div className="relative">
                      <label className="font-medium text-gray-900">Email</label>
                      {!this.state.emailError ? 
                        <input
                          onChange={event => this.setState({...this.state,email: event.target.value})}
                          type="text"
                          className="block w-full px-4 py-4 mt-2 text-xl placeholder-gray-400 bg-gray-200 rounded-lg focus:outline-none focus:ring-4 focus:ring-blue-600 focus:ring-opacity-50"
                          placeholder="Enter Your Email Address"
                        />
                      :
                        <input
                          onChange={event => this.setState({...this.state,email: event.target.value, emailError: false})}
                          type="text"
                          className="block w-full px-4 py-4 mt-2 text-xl placeholder-gray-400 bg-gray-200 rounded-lg ring outline-none ring-4 ring-opacity-50 ring-red-600 focus:outline-none focus:ring-4 focus:ring-blue-600 focus:ring-opacity-50"
                          placeholder="Enter Your Email Address"
                        />
                      }
                    </div>
                    <div className="relative">
                      <label className="font-medium text-gray-900">
                        Password
                      </label>
                      {!this.state.passwordError ? 
                        <input
                          onChange={event => this.setState({...this.state,password1: event.target.value})}
                          type="password"
                          className="block w-full px-4 py-4 mt-2 text-xl placeholder-gray-400 bg-gray-200 rounded-lg focus:outline-none focus:ring-4 focus:ring-blue-600 focus:ring-opacity-50"
                          placeholder="Password"
                        />
                      :
                        <input
                          onChange={event => this.setState({...this.state,password1: event.target.value, passwordError: false})}
                          type="password"
                          className="block w-full px-4 py-4 mt-2 text-xl placeholder-gray-400 bg-gray-200 rounded-lg ring outline-none ring-4 ring-opacity-50 ring-red-600 focus:outline-none focus:ring-4 focus:ring-blue-600 focus:ring-opacity-50"
                          placeholder="Password"
                        />
                      }
                    </div>
                    <label className="text-red-500">{this.state.passwordError ? "* Password must be 10-32 characters, containing an uppercase, lowercase, and special character" : ""}</label>
                    <div className="relative">
                      <label className="font-medium text-gray-900">
                        Confirm Password
                      </label>
                      {!this.state.matchError ? 
                        <input
                          type="password"
                          onChange={event => this.setState({...this.state,password2: event.target.value})}
                          className="block w-full px-4 py-4 mt-2 text-xl placeholder-gray-400 bg-gray-200 rounded-lg focus:outline-none focus:ring-4 focus:ring-blue-600 focus:ring-opacity-50"
                          placeholder="Confirm Password"
                        />
                      :
                        <input
                          type="password"
                          onChange={event => this.setState({...this.state,password2: event.target.value, passwordMatch: false})}
                          className="block w-full px-4 py-4 mt-2 text-xl placeholder-gray-400 bg-gray-200 rounded-lg ring outline-none ring-4 ring-opacity-50 ring-red-600 focus:outline-none focus:ring-4 focus:ring-blue-600 focus:ring-opacity-50"
                          placeholder="Confirm Password"
                        />
                      }
                    </div>
                    <label className="text-red-500">{this.state.matchError ? "* Passwords must match" : ""}</label>

                    
                    <div className="">
                      <h4 className="w-full text-3xl font-bold">Organization</h4>
                      <p className="text-lg text-gray-500 my-0">an organization should only be created once</p>
                    </div>
                    <div className="relative">
                      <div className="flex flex-row items-start mt-2 space-x-4">
                        <button
                          type="button"
                          className="inline-flex items-center justify-center px-8 py-2 rounded-md bg-primary hover:bg-accent w-full h-12 text-sm font-small leading-6 text-white"
                          onClick={event => this.setState({...this.state,newOrg: false})}>
                          Join Org
                        </button>
                        <button
                          type="button"
                          className="inline-flex items-center justify-center px-8 py-2 rounded-md bg-primary hover:bg-accent w-full h-12 text-sm font-small leading-6 text-white"
                          onClick={event => this.setState({...this.state,newOrg: true})}>
                          Create Org
                        </button>
                      </div>
                    </div>
                    <div className="relative">
                      {this.state.newOrg ? <label className="font-medium mt-8 text-gray-900">Organization Name</label> : null}
                      {this.state.newOrg ? <input
                        onChange={event => this.setState({...this.state,org: event.target.value})}
                        type="text"
                        className="block w-full px-4 py-4 mt-2 text-xl placeholder-gray-400 bg-gray-200 rounded-lg focus:outline-none focus:ring-4 focus:ring-blue-600 focus:ring-opacity-50"
                        placeholder="Organization"
                      /> : null}
                    </div>
                    
                    <div className="relative">
                      <a
                        onClick={event => this.checkErrors()}
                        className="inline-block w-full px-5 py-4 text-lg font-medium text-center text-white transition duration-150 bg-primary rounded-lg hover:bg-accent ease"
                      >
                        Create Account
                      </a>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
      </>
    );
  }
}

export default withRouter(SignupInput);
