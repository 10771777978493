import React, { Component } from 'react';
import axios from 'axios';
import { ChangePassword } from '../modals/ChangePassword';
import { withRouter } from "react-router";
import Management from '../Management';
import { INFO_KEY, GET_ACCOUNT_PATH, LOGIN_PAGE_PATH, UPDATE_USER_PATH, UPDATE_USER_TITLE, PERMISSIONS } from '../../resources/Vault';
import { ErrorToast }  from '../helperComponents/ErrorToast';
import { SuccessToast } from "../helperComponents/SuccessToast";

/**
 * Dashboard page, displays user info and organization management 
 */
class Dashboard extends Component {
  constructor() {
    super();
    /**
     * Dashboard page state 
     * @type {Object}
     * @property {string} firstName User first name
     * @property {string} lastName User last name
     * @property {string} email User email
     * @property {boolean} editing Is user editing info
     * @property {boolean} showModal Is modal visible
     * @property {Object} permission User permission level
     * @property {string} checkFirst Copy of firstName
     * @property {string} checkLast Copy of lastName
     * @property {string} checkEmail Copy of email
     * @property {boolean} blankFields Are any fields blank
     */
    this.state = {
      firstName:"",
      lastName:"",
      email:"",
      editing: false,
      showModal: false,
      permission: JSON.parse(localStorage.getItem(INFO_KEY)).permission,
      checkFirst: "",
      checkLast: "",
      checkEmail: "",
      blankFields: false,
      showAPIErrorToast: false,
      showAPISuccessToast: false,
    };
  this.handleOnClose = this.handleOnClose.bind(this);
  this.onSubmit = this.onSubmit.bind(this);
  this.setEdit = this.setEdit.bind(this);
  this.checkErrors = this.checkErrors.bind(this);
  }

  /**
   * Closes modal window
   */
  handleOnClose () {
    this.setState({...this.state,showModal: false});
  };

  /**
   * Component lifecycle method, runs before mount
   * Gets user information
   */
  componentWillMount() {
    document.title = "Dashboard | Patient Management Portal"
    axios.get(GET_ACCOUNT_PATH, {
      headers: {
        "x-access-token": localStorage.getItem("auth_token")
      }
      })
      .then(response => {
        if(response.data.auth === true) {
          // If authorization is successful, set the data
          this.setState({...this.state, 
          firstName: response.data.user.firstName, 
          lastName: response.data.user.lastName,
          email: response.data.user.email,
          org: response.data.user.org});
        } else {
          this.props.history.push({pathname:LOGIN_PAGE_PATH});
        }
                
      }).catch(err => {
        this.props.history.push({pathname:LOGIN_PAGE_PATH});
        console.log(err);
    });
  }

  /**
   * Activates/deactivates editing state and associated state variables
   */
  setEdit() {
    // Activate editing
    if(!this.state.editing) {
      // Save previous states
      this.setState({
        ...this.state,
        editing: true,
        checkFirst: this.state.firstName,
        checkLast: this.state.lastName,
        checkEmail: this.state.email
      });
    } 
    // Deactivate editing 
    else {
      // Reset input field text to initial values
      document.getElementById("fname").value = this.state.checkFirst;
      document.getElementById("lname").value = this.state.checkLast;
      document.getElementById("email").value = this.state.checkEmail;

      // Reset states
      this.setState({
        ...this.state,
        editing: false,
        firstName: this.state.checkFirst,
        lastName: this.state.checkLast,
        email: this.state.checkEmail,
        checkFirst: "",
        checkLast: "",
        checkEmail: "",
        blankFields: false
      });  
    }
  }

  /**
   * Checks input fields for errors/ state change
   */
  checkErrors() {
    // Check for blank fields
    if (this.state.firstName === "" || 
        this.state.lastName === "" ||
        this.state.email === "") {
          this.setState({
            ...this.state,
            blankFields: true
          });
    }
    // Check for changed fields 
    else if (this.state.firstName === this.state.checkFirst &&
             this.state.lastName === this.state.checkLast &&
             this.state.email === this.state.checkEmail){
              // Nothing changed, stop editing
              this.setEdit();
    }
    else {
      this.onSubmit();
    }

  }
    
  /**
   * Submits user info change to backend
   */
  onSubmit(){
    axios.put(UPDATE_USER_PATH, {
      title: UPDATE_USER_TITLE,
      info: {
        "firstName" : this.state.firstName,
        "lastName" : this.state.lastName,
        "email" : this.state.email
      }
    },
    {
      headers: {
        "x-access-token": localStorage.getItem("auth_token")
      }
    }).then((res) =>{
      this.setState({
        ...this.state,
        firstName: res.data.user.firstName,
        lastName: res.data.user.lastName,
        email: res.data.user.email,
        showAPIErrorToast: false,
        showAPISuccessToast: true,
      });
      document.getElementById("fname").value = res.data.user.firstName;
      document.getElementById("lname").value = res.data.user.lastName;
      document.getElementById("email").value = res.data.user.email;
    }).catch((error) => {
      console.log(error);
      this.setState({
        ...this.state,
        showAPIErrorToast: true,
        showAPISuccessToast: false,
      });
    });
    // Reset check states and editing
    this.setState({
      ...this.state,
      editing: false,
      checkFirst: "",
      checkLast: "",
      checkEmail: ""
    });
  }

  render() {
    return (
      <section className="w-screen h-screen flex flex-row">
        {/* Start of User Info Container */}
        <div className="w-1/5">
          <div className="flex flex-row justify-center">
            <div className="flex flex-col items-center justify-start w-full h-full mb-10 px-4">
              <label className="text-3xl text-center font-bold w-full shadow-lg bg-[#E5E4E2] rounded py-6 mt-4 border-b-4 border-[#D3D3D3]">User Info</label>
              <div className="relative w-full mt-10 px-4">
                <form>
                  <fieldset disabled={!this.state.editing}>
                    <div className="space-y-8">
                      <div className="relative">
                        <label className="font-medium text-gray-900">
                          First Name
                        </label>
                        <input
                          type="text"
                          id="fname"
                          className="block w-full px-4 py-4 mt-2 text-xl placeholder-gray-400 bg-gray-200 rounded-lg focus:outline-none focus:ring-4 focus:ring-blue-600 focus:ring-opacity-50"
                          defaultValue={this.state.firstName}
                          onChange={event =>this.setState({...this.state, firstName: event.target.value})} />
                      </div>
                      <div className="relative">
                        <label className="font-medium text-gray-900">
                          Last Name
                        </label>
                        <input
                          type="text"
                          id="lname"
                          className="block w-full px-4 py-4 mt-2 text-xl placeholder-gray-400 bg-gray-200 rounded-lg focus:outline-none focus:ring-4 focus:ring-blue-600 focus:ring-opacity-50"
                          defaultValue={this.state.lastName}
                          onChange={event =>this.setState({...this.state, lastName: event.target.value})} />
                      </div>
                      <div className="relative">
                        <label className="font-medium text-gray-900">Email</label>
                        <input
                          type="email"
                          id="email"
                          className="block w-full px-4 py-4 mt-2 text-xl placeholder-gray-400 bg-gray-200 rounded-lg focus:outline-none focus:ring-4 focus:ring-blue-600 focus:ring-opacity-50"
                          defaultValue={this.state.email}
                          onChange={event =>this.setState({...this.state, email: event.target.value})} />
                      </div>
                      <label className="text-red-500">{this.state.blankFields ? "Fields cannot be left blank" : ""}</label>
                    </div>
                  </fieldset>
                </form>
                <div className= "py-8">
                  <div className="flex flex-row items-start space-x-4">
                    <button
                      type="button"
                      className="inline-flex items-center justify-center px-8 py-2 rounded-md bg-primary hover:bg-accent w-full h-12 text-base font-small leading-6 text-white"
                      onClick={() =>this.setEdit()}>
                        {this.state.editing ? 'Cancel' : 'Edit'}
                    </button>
                    {this.state.editing ? <button
                      type="submit"
                      className="inline-flex items-center justify-center px-8 py-2 rounded-md bg-primary hover:bg-accent w-full h-12 text-base font-small leading-6 text-white"
                      onClick={() => this.checkErrors()}>
                        Save
                    </button> : null}
                  </div>
                </div>
                <button
                      type="submit"
                      className="inline-flex items-center justify-center px-8 py-2 rounded-md bg-primary hover:bg-accent w-full h-12 text-base font-small leading-6 text-white"
                      onClick={() => this.setState({...this.state,showModal: true})}>
                        Change Password
                </button>
              </div>
            </div>
            <ChangePassword
              visible={this.state.showModal}
              handleOnClose = {this.handleOnClose}
            />
          </div>
        </div>
        <div className="w-4/5 px-4 border-l-2 border-black">
          {this.state.permission === PERMISSIONS.OWNER | this.state.permission === PERMISSIONS.ADMIN ? <Management /> : null}
        </div>

        {/* API Toast */}
        <div className="absolute bottom-0 right-0 mr-10 mb-6">
          {this.state.showAPIErrorToast ? <ErrorToast message={"There was an issue editing user info"} visible={this.state.showAPIErrorToast}/> : null}
          {this.state.showAPISuccessToast ? <SuccessToast message={"User info updated"} visible={this.state.showAPISuccessToast}/> : null}
        </div>
      </section>
    )
  }
}
export default withRouter(Dashboard);
