import React, { useEffect } from 'react';
import LoginInput from '../LoginInput'

/**
 * Login page, displays LoginInfo component
 * @returns JSX to render login page
 */
function Login() {
  
    useEffect(() => {
        document.title = "Login | Patient Management Portal"
    }, [])

    return (
    <>
      <LoginInput />
    </>
  )
}

export default Login;
