import React, { Component } from "react";
import axios from "axios";
import { withRouter, Redirect } from 'react-router';
import { LOGIN_PATH, LOGIN_TITLE, AUTH_TOKEN_KEY, INFO_KEY, SIGNUP_PAGE_PATH, PORTAL_PAGE_PATH } from '../resources/Vault';

/**
 * Renders login component, housing all logic for logging in
 */
class LoginInput extends Component {
  constructor() {
    super();
    /**
     * Login component state
     * @type {Object}
     * @property {string} email Input email
     * @property {string} password Input password
     * @property {boolean} error Empty fields?
     * @property {boolean} passwordError Incorrect password?
     */
    this.state = {
      email: "",
      password: "",
      error: false,
      passwordError: false,
      opacity: false,
    }
    // Binding functions
    this.onSubmit = this.onSubmit.bind(this)
    this.handleKeypress = this.handleKeypress.bind(this)
  };

  /**
   * Check for empty input fields
   */
  checkErrors () {
    if(this.state.email == "" | this.state.password == "") {
      this.setState({...this.state,error: true});
    } else {
      this.onSubmit();
    }
  }

  /**
   * Submit user input to backend
   */
  onSubmit = async () => {
    if (!this.state.error) {
      this.setState({...this.state, opacity: true});
      axios
        .post(LOGIN_PATH, {
          title: LOGIN_TITLE,
          user_info: {
            email: this.state.email,
            password: this.state.password,
          }
      })
        .then((response) => {
          // In prodution, this will redirect to DUO push
          if(response.status == 209) {
            window.location.href = response.data.location;
            return;
          }

          // On dev env, save token and acc info
          this.setState({...this.state, opacity: false});
          localStorage.setItem(AUTH_TOKEN_KEY, response.data.auth_token);
          localStorage.setItem(INFO_KEY, JSON.stringify(response.data.acc));
          this.props.history.push({pathname: PORTAL_PAGE_PATH})
        })
        .catch((err) => {
          this.setState({...this.state, opacity: false});
          console.log(err);
          if (err.response) {
            console.log(err.response.data);
            console.log(err.response.status);
            console.log(err.response.headers);
          }
          else if (err.request) {
            console.log(err.request);
          }
          else {
            console.log(err.message);
          }
          console.log(err.config);
          this.setState({...this.state,passwordError: true});
        });
    }
  };

  /**
   * Checks for enter key being pressed
   * @param {event} e Key press event
   */
  handleKeypress(e) {
    if (e.charCode === 13) {
      this.checkErrors();
    }
  };

  render () {
    return (
      <>
        <section className={"w-full bg-white " + (this.state.opacity ? "opacity-50 pointer-events-none" : "opacity-100")}>
          <div className="flex w-1/2 flex-row justify-center">
            <div className="w-full px-10 py-16 ml-auto mr-auto bg-white xl:py-32 md:w-3/5 lg:w-4/5 xl:w-4/5">
              <div className="-ml-3">
                <a
                  href="/"
                  className="relative flex items-center h-5 font-black leading-none"
                >
                </a>
              </div>

              <p className="mb-4 text-2xl mt-10 font-extrabold leading-snug tracking-tight text-left text-primary md:text-4xl">
                Login
              </p>
              <div className="relative">
                <label className="font-medium text-gray-900">Email</label>
                <input
                  onChange={event => this.setState({...this.state,email: event.target.value, error:false})}
                  onKeyPress={this.handleKeypress}
                  type="text"
                  className="block w-full px-4 py-4 mt-2 text-xl placeholder-gray-400 bg-gray-200 rounded-lg focus:outline-none focus:ring-4 focus:ring-blue-600 focus:ring-opacity-50"
                  placeholder="Email"
                />
              </div>
              <div className="relative">
                <label className="font-medium text-gray-900">
                  Password
                </label>
                <input
                  onChange={event => this.setState({...this.state,password: event.target.value, error:false})}
                  onKeyPress={this.handleKeypress}
                  type="password"
                  className="block w-full px-4 py-4 mt-2 text-xl placeholder-gray-400 bg-gray-200 rounded-lg focus:outline-none focus:ring-4 focus:ring-blue-600 focus:ring-opacity-50"    
                  placeholder="Password"
                />
                
                <label className="text-red-500">{this.state.passwordError ? "* Incorrect Password" : ""}</label>
                <label className="text-red-500">{!this.state.passwordError && this.state.error ? "* Fields Cannot Be Blank" : ""}</label>
              </div>
              <div className="mt-8 space-y-10" onClick={event => this.checkErrors()}>
                <div className="grid grid-cols-1 gap-4">
                  <div className="flex items-center justify-center w-full pt-3 pb-3 text-white bg-blue-500 rounded-md cursor-pointer hover:bg-blue-600">
                    <p>Login</p>
                  </div>
                </div>
              </div>
              <div className="pt-6 mt-6 border-t border-gray-200">
                <p className="inline text-sm font-medium text-gray-700">
                  Don't have an account?
                </p>
                <a
                  className="inline text-sm font-medium text-purple-700 hover:text-purple-900"
                  href= { SIGNUP_PAGE_PATH }
                >
                  Create an account
                </a>
              </div>
            </div>
          </div>
        </section>
      </>
    )
  }
}

export default withRouter(LoginInput);