import React, { Component } from 'react';
import { get_progress } from '../utils/session-collection-progress';

export class ComplianceTriageObject extends Component {
  constructor(props) {
    super(props);
    this.state = {
    };
    this.getEndDate = this.getEndDate.bind(this);
    this.getColor = this.getColor.bind(this);
    this.formatDate = this.formatDate.bind(this);
  };

  /**
   * Formats a datestring to calculate end date of a session
   * @param {string} startDate Session start date
   * @param {number} daysAfter Session period
   * @returns Formatted End-Date string
   */
  getEndDate(startDate, daysAfter) {
    const endDate = new Date(startDate);
  
    endDate.setDate(endDate.getDate() + (daysAfter - 1));
    let formatDate = (endDate.getMonth() + 1) + "/" + endDate.getDate() + "/" + endDate.getFullYear(); 
    return formatDate;
  };

  /**
   * Formats the start date
   * @param {string} date Date string 
   * @returns Formatted Date String
   */
  formatDate(date) {
    const date_obj = new Date(date);

    let formatDate = (date_obj.getMonth() + 1) + "/" + date_obj.getDate() + "/" + date_obj.getFullYear();
    return formatDate;
  }

  /**
   * Using compiance data, detemine color to render
   * @returns int code representing color to render box
   * 0 -> Render the box green
   * 1 -> Render the box yellow
   * 2 -> Render the box red
   */
  getColor() {
    if (this.state.compliant == true) {
      if (this.state.progress == 0) {
        return 0;
      } else if (this.state.progress == 1 || this.state.progress == 3) {
        return 1;
      } else {
        return 2;
      }
    } else {
      return 2;
    }
  };

  render() {
    if(this.getColor() === 0) {
      return(
        <div className={"hover:bg-green-400 hover:border-2 hover: border-green-500 cursor-pointer rounded-lg w-full p-5 mt-4 font-bold text-xs space-y-2 bg-green-300 shadow-xl"}>
          <div className="flex justify-center items-center">
            <label className="font-bold">{this.props.name}</label>
          </div>
          <div className="flex justify-center items-center">
            <label>Start Date: {this.formatDate(this.props.start_date)} </label>
          </div>
          <div className="flex justify-center items-center">
            <label>End Date: {this.getEndDate(this.props.start_date, this.props.rules.period)}</label>
          </div>
          <div className="flex justify-center items-center text-center">
            <label>Session Name: {this.props.rules.title}</label>
          </div>
        </div>
      )
    } else if (this.getColor() === 1) {
      return(
        <div className={"hover:bg-yelow-400 hover:border-2 hover: border-yellow-500 cursor-pointer rounded-lg w-full p-5 mt-4 font-bold text-xs space-y-2 bg-yellow-300 shadow-xl"}>
          <div className="flex justify-center items-center">
            <label className="font-bold">{this.props.name}</label>
          </div>
          <div className="flex justify-center items-center">
            <label>Start Date: {this.formatDate(this.props.start_date)} </label>
          </div>
          <div className="flex justify-center items-center">
            <label>End Date: {this.getEndDate(this.props.start_date, this.props.rules.period)}</label>
          </div>
          <div className="flex justify-center items-center text-center">
            <label>Session Name: {this.props.rules.title}</label>
          </div>
        </div>
      )
    } else {
      return(
        <div className={"hover:bg-red-400 hover:border-2 hover: border-red-500 cursor-pointer rounded-lg w-full p-5 mt-4 font-bold text-xs space-y-2 bg-red-300 shadow-xl"}>
          <div className="flex justify-center items-center">
            <label className="font-bold">{this.props.name}</label>
          </div>
          <div className="flex justify-center items-center">
            <label>Start Date: {this.formatDate(this.props.start_date)} </label>
          </div>
          <div className="flex justify-center items-center">
            <label>End Date: {this.getEndDate(this.props.start_date, this.props.rules.period)}</label>
          </div>
          <div className="flex justify-center items-center text-center">
            <label>Session Name: {this.props.rules.title}</label>
          </div>
        </div>
      )
    }
  }
}
