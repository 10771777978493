import { Category, DateTimeCategory, ChartComponent, SplineSeries, TrendlineDirective, TrendlinesDirective, Trendlines, Inject, Legend, LineSeries, SeriesCollectionDirective, SeriesDirective, Tooltip } from '@syncfusion/ej2-react-charts';
import { FORM_GRAPH, CLASSIFICATION_GRAPH } from '../../resources/Config';
import React, { Component } from "react";

/**
 * Generic line chart with configurable properties
 * @property {string} labels.title Title of the graph
 * @property {string} labels.dataKeyX X-axis data key
 * @property {string} labels.dataKeyY Y-axis data key
 * @property {Array<string>} data IMU data
 */
class LineChart extends Component {
    constructor(props) {
    super(props);
    this.primaryxAxis = { valueType: 'DateTimeCategory', labelFormat: "yMMMdd"};
    this.tooltip = {
      enable: true, 
      format: '${point.y}',
      fill: '#704223A',
      border: {
        width: 2,
        color: 'grey'
      }
    };
    let legendSide = props.legendSide;
    this.legendSettings = { visible: true, position: legendSide};
    this.marker = { visible: true, width: 10, height: 10};
    this.titlestyle = {
      fontFamily: "Arial",
      fontWeight: 'bold',
      color: "#04223A",
      size: '23px'
    };

    this.pointRender = this.pointRender.bind(this);
    this.pointClicked = this.pointClicked.bind(this);
  }

  /**
   * When rendering a point, if it is flagged, then we can color the point red
   * @param {*} point Datapoint in the line chart
   */
  pointRender(point) {
    if(this.props.data[point.point.xValue].evaluation){
      point.fill = '#FA5F55';
    }
  }

  /**
   * Triggers opening the granular data component on click
   * @param {*} point Datapoint in the line chart
   */
  pointClicked(point) {
    if(this.props.data[point.point.xValue].evaluation) {
      this.props.showGranularData(true, point.point.x.toString(), this.props.data[point.point.xValue], true);
    } else {
      this.props.showGranularData(true, point.point.x.toString(), this.props.data[point.point.xValue], false);
    }
  }

  render () {
    return(
      <>
        <ChartComponent
          title={this.props.labels.title}
          tooltip={this.tooltip}
          legendSettings={this.legendSettings}
          primaryXAxis={{valueType:"DateTimeCategory", labelFormat:"yMMMdd"}}
          primaryYAxis={{title:this.props.labels.y}}
          name = {this.props.labels.title}
          highlightColor='red'
          titleStyle={this.titlestyle}
          pointRender={this.pointRender}
          pointClick={this.pointClicked}>
          <Inject services={[LineSeries, Legend, Tooltip, SplineSeries, Category, Trendlines,  DateTimeCategory]}/>
            <SeriesCollectionDirective>
              <SeriesDirective
                type="Line"
                marker={this.marker}
                name={this.props.labels.title}
                width="5"
                fill="#04223A"
                dataSource = {this.props.data}
                xName={this.props.labels.dataKeyX}
                yName={this.props.labels.dataKeyY}
              >
                {this.props.data.length > 2 ?
                <TrendlinesDirective>
                  <TrendlineDirective type='Polynomial' width={3} name='Trend' fill="#F5A623">
                  </TrendlineDirective>
                </TrendlinesDirective>
                : null}
              </SeriesDirective>
            </SeriesCollectionDirective>
        </ChartComponent>
      </>
    );
  }
}

export { LineChart };
