import React, { Component } from 'react';

export class RespiratoryObject extends Component {
  constructor(props) {
    super(props);
    this.state = {
      render: true,
      data: this.props.data
    };
  };
  render() {
    if(this.state.render) {
      return(
        <div className={"hover:bg-gray-300 border-4 rounded-3xl w-72 p-3 mb-2 " + (this.props.monitorSession ? (this.props.compliant ? "border-green-600": "border-red-700") : "border-blue-700")}>
          <label className="flex justify-center font-bold text-gray-900"> {this.props.firstName} {this.props.lastName} </label>
          <div className="flex justify-center">
            <label className="font-normal text-gray-900"> MRN: </label> 
            <label className="font-semibold text-gray-900"> {this.props.mrn} </label>
          </div>
          <div className="flex justify-center">
            <label className="font-normal text-gray-900"> Inhaler Adherence: </label> 
            <label className="font-semibold text-gray-900"> {this.props.inhalerAdherence} </label>
          </div>
          <div className="flex justify-center">
            <label className="font-normal text-gray-900"> Breaths Per Minute: </label> 
            <label className="font-semibold text-gray-900"> {this.props.breathsPerMinute} </label>
          </div>
          <div className="flex justify-center">
            <label className="font-normal text-gray-900"> Average Peak Flow: </label> 
            <label className="font-semibold text-gray-900"> {this.props.avgPeakFlow} </label>
          </div>
          <div className="flex justify-center">
            <label className="font-normal text-gray-900"> Average Spo2: </label> 
            <label className="font-semibold text-gray-900"> {this.props.avgSpo2} </label>
          </div>
        </div>
      )
    } else {
      return(null)
    }
  }
}
