import React, { Component } from 'react';
import { get_progress } from '../../utils/session-collection-progress';

export class ComplianceObject extends Component {
  constructor(props) {
    super(props);
    this.state = {
      progress: get_progress(props.sets.length, props.rules.min_threshold, props.housekeeping.collection_progress),
      compliant: !(Object.keys(props.evaluation).length > 0)
    };
    this.getEndDate = this.getEndDate.bind(this);
    this.getColor = this.getColor.bind(this);
    this.formatDate = this.formatDate.bind(this);
  };

  /**
   * Formats a datestring to calculate end date of a session
   * @param {string} startDate Session start date
   * @param {number} daysAfter Session period
   * @returns Formatted End-Date string
   */
  getEndDate(startDate, daysAfter) {
    const endDate = new Date(startDate);
  
    endDate.setDate(endDate.getDate() + (daysAfter - 1));
    let formatDate = (endDate.getMonth() + 1) + "/" + endDate.getDate() + "/" + endDate.getFullYear(); 
    return formatDate;
  };

  /**
   * Formats the start date
   * @param {string} date Date string 
   * @returns Formatted Date String
   */
  formatDate(date) {
    const date_obj = new Date(date);

    let formatDate = (date_obj.getMonth() + 1) + "/" + date_obj.getDate() + "/" + date_obj.getFullYear();
    return formatDate;
  }

  /**
   * Using compiance data, detemine color to render
   * @returns Tailwind color string
   */
  getColor() {
    if (this.state.compliant == true) {
      if (this.state.progress == 0) {
        return "border-green-600";
      } else if (this.state.progress == 1 || this.state.progress == 3) {
        return "border-yellow-200";
      } else {
        return "border-red-700";
      }
    } else {
      return "border-red-700";
    }
  };

  render() {
    return(
      <>
      <div className={"hover:bg-gray-300 border-4 rounded-3xl w-full p-5 mt-4 font-bold text-xs space-y-2 " + this.getColor()}>
        <div className="flex justify-center items-center">
          <label className="font-bold">{this.props.name} </label>
        </div>
        <div className="flex justify-center items-center">
          <label>Start Date: {this.formatDate(this.props.start_date)} </label>
        </div>
        <div className="flex justify-center items-center">
          <label>End Date: {this.getEndDate(this.props.start_date, this.props.rules.period)}</label>
        </div>
        <div className="flex justify-center items-center text-center">
          <label>Session Name: {this.props.rules.title}</label>
        </div>
      </div>
      </>
    )
  }
}
