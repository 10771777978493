import React, { Component } from "react";
import { MONTH_LABELS, DATE_LABELS, YEAR_LABELS } from  '../../resources/Config';
import { Dropdown } from '../Dropdown';
import axios from 'axios';
import { DATA_KEY, ADD_MEDICATION_PATH, ADD_MEDICATION_TITLE } from '../../resources/Vault';


export class AddMedication extends Component{
  constructor(props) {
    super(props);
    let d = new Date();
    this.state = {
      patientData: JSON.parse(sessionStorage.getItem(DATA_KEY)),
      name:"",
      start:"01/01/" + d.getFullYear(),
      startDay:"01",
      startMonth:"01",
      startYear: d.getFullYear(),
      end:"01/01/" + d.getFullYear(),
      endDay:"01",
      endMonth:"01",
      endYear: d.getFullYear(),
      error: false,
      opacity: false,
    };
    this.onSubmit = this.onSubmit.bind(this);
    this.checkErrors = this.checkErrors.bind(this);
    this.handleKeypress = this.handleKeypress.bind(this);
    this.close = this.close.bind(this);
    this.setStartMonth = this.setStartMonth.bind(this);
    this.setStartDate = this.setStartDate.bind(this);
    this.setStartYear = this.setStartYear.bind(this);
    this.setEndMonth = this.setEndMonth.bind(this);
    this.setEndDate = this.setEndDate.bind(this);
    this.setEndYear = this.setEndYear.bind(this);
    this.formatDate = this.formatDate.bind(this);
  }

  /**
   * Function which checks for errors and then closes the modal
   * If we have no errors then submit, else show error message
   */
  checkErrors () {
    if(this.state.name == "" | 
      this.state.startDay == "" |
      this.state.startMonth == "" |
      this.state.startYear == "" |
      this.state.endDay == "" | 
      this.state.endMonth == "" | 
      this.state.endYear == ""
      ){
      this.setState({...this.state,error:true})
    } else {
      this.onSubmit()
    }
  }

  /**
   * 
   * @param {*} e 
   * Function which detects the clicking of the enter key
   * When enter is clicked, then we call the checkErrors method which submits the modal
   */
  handleKeypress(e) {
    if (e.charCode === 13) {
      this.checkErrors();
    }
  };

  /**
   * Function which submits the modals
   * We call the handle on close method of the parent component and close the modal
   */
  onSubmit () {
    this.setState({...this.state, opacity: true});

    var payload = {};
    payload[this.state.name] = {
      startDate: this.state.start,
      endDate: this.state.end
    };
    axios.post(ADD_MEDICATION_PATH, {
      title: ADD_MEDICATION_TITLE,
      info: {
        pid: this.state.patientData.pid
      },
      med: payload
    },
    {
      headers: {
        "x-access-token": localStorage.getItem("auth_token")
      }
    }).then((res) => {
      this.setState({...this.state, opacity: false});
      this.props.updateMeds(res.data);
      this.close();
    }).catch((error) => {
      this.setState({...this.state, opacity: false});
      console.log(error);
    });
  };

  /**
   * Function which closes the modal window
   * After closing the window, we reset state to default
   */
  close () {
    this.props.handleOnExitAddMedication();
    let year = new Date().getFullYear();
    this.setState({
      name:"",
      start: "01/01/" + year,
      end: "01/01/" + year,
      startDay:"01",
      startMonth:"01",
      startYear: year,
      endDay:"01",
      endMonth:"01",
      endYear: year,
      error: false
  })
  }

  /**
   * Format date given input
   * @param {int} param 0=MM, 1=DD, 2=YYYY, 3-5: for end
   * @param {string} num Given number to format
   * @returns Formatted date string
   */
  formatDate(param, num) {
    let date;
    switch (param) {
      case 0:
        date = num + "/" + this.state.startDay + "/" + this.state.startYear;
        break;
      case 1:
        date = this.state.startMonth + "/" + num + "/" + this.state.startYear;
        break;
      case 2:
        date = this.state.startMonth + "/" + this.state.startDay + "/" + num;
        break;
      case 3:
        date = num + "/" + this.state.endDay + "/" + this.state.endYear;
        break;
      case 4:
        date = this.state.endMonth + "/" + num + "/" + this.state.endYear;
        break;
      case 5:
        date = this.state.endMonth + "/" + this.state.endDay + "/" + num;
        break;
    }
    return date;
  }

  setStartMonth (month) {
    this.setState({...this.state,
                  startMonth: month,
                  start: this.formatDate(0, month)});
  }
  setStartYear (year) {
    this.setState({...this.state,
                  startYear: year,
                  start: this.formatDate(2, year)});
  }
  setStartDate (date) {
    this.setState({...this.state,
                  startDay: date,
                  start: this.formatDate(1, date)});
  }
  setEndMonth (month) {
    this.setState({...this.state,
                  endMonth: month,
                  end: this.formatDate(3, month)});
  }
  setEndYear (year) {
    this.setState({...this.state,
                  endYear: year,
                  end: this.formatDate(5, year)});
  }
  setEndDate (date) {
    this.setState({...this.state,
                  endDay: date,
                  end: this.formatDate(4, date)});
  }

  render(){
    if (!this.props.visible) return null;
    return (
      <>
        <div className={"fixed mt-10 inset-0 bg-black bg-opacity-30 backdrop-blur-sm flex justify-center items-center " + (this.state.opacity ? "opacity-50 pointer-events-none" : "opacity-100")}>
          <div className="bg-white p-4 rounded">
          <div className="flex justify-center">
              <h1 
                className="font-semibold text-center text-xl text-gray-700 mx-16"
                >
                Add Medication
              </h1>
              <button 
                className="font-semibold text-center text-xl text-gray-700 w-7 rounded-xl hover:bg-red-400"
                onClick={() => this.close()}>
                X
              </button>
            </div>
            <div className="relative">
              <label className="font-medium text-gray-900">
                Medication Name
              </label>
              
              {this.state.error ? 
              <input
                onChange={event => this.setState({...this.state,name:event.target.value})}
                onKeyPress={this.handleKeypress}
                className="block w-full px-4 py-4 mt-2 text-xl placeholder-gray-400 bg-gray-200 rounded-lg ring outline-none ring-4 ring-opacity-50 ring-red-600 focus:outline-none focus:ring-4 focus:ring-blue-600 focus:ring-opacity-50"
                placeholder="Name"
              />
              :
              <input
                onChange={event => this.setState({...this.state,name:event.target.value})}
                onKeyPress={this.handleKeypress}
                className="block w-full px-4 py-4 mt-2 text-xl placeholder-gray-400 bg-gray-200 rounded-lg focus:outline-none focus:ring-4 focus:ring-blue-600 focus:ring-opacity-50"
                placeholder="Name"
              />
              }
            </div>

            <div className="relative">
              <label className="font-medium text-gray-900">
                Start Date
              </label>
              <div className="flex items-stretch">
                <Dropdown
                  options={MONTH_LABELS} 
                  setValue={this.setStartMonth}
                  buttonStyle="flex justify-center border-2 border-black rounded-lg w-20 p-3 mr-1 hover:bg-gray-300"
                  menuStyle="absolute z-40 bg-white max-h-24 overflow-auto border-l-2 border-r-2 border-b-2 border-black rounded-lg w-20 p-1 mr-1 shadow-xl"/>
                <Dropdown
                  options={DATE_LABELS} 
                  setValue={this.setStartDate}
                  buttonStyle="flex justify-center border-2 border-black rounded-lg w-20 p-3 mr-1 hover:bg-gray-300"
                  menuStyle="absolute z-40 bg-white max-h-24 overflow-auto border-l-2 border-r-2 border-b-2 border-black rounded-lg w-20 p-1 mr-1 shadow-xl"/>
                <Dropdown
                  options={YEAR_LABELS} 
                  setValue={this.setStartYear}
                  buttonStyle="flex justify-center border-2 border-black rounded-lg w-20 p-3 mr-1 hover:bg-gray-300"
                  menuStyle="absolute z-40 bg-white max-h-24 overflow-auto border-l-2 border-r-2 border-b-2 border-black rounded-lg w-20 p-1 mr-1 shadow-xl"/>
              </div>
            </div>

            <div className="relative">
              <label className="font-medium text-gray-900">
                End Date
              </label>
              <div className="flex items-stretch">
                <Dropdown
                  options={MONTH_LABELS} 
                  setValue={this.setEndMonth}
                  buttonStyle="flex justify-center border-2 border-black rounded-lg w-20 p-3 mr-1 hover:bg-gray-300"
                  menuStyle="absolute z-40 bg-white max-h-24 overflow-auto border-l-2 border-r-2 border-b-2 border-black rounded-lg w-20 p-1 mr-1 shadow-xl"/>
                <Dropdown
                  options={DATE_LABELS} 
                  setValue={this.setEndDate}
                  buttonStyle="flex justify-center border-2 border-black rounded-lg w-20 p-3 mr-1 hover:bg-gray-300"
                  menuStyle="absolute z-40 bg-white max-h-24 overflow-auto border-l-2 border-r-2 border-b-2 border-black rounded-lg w-20 p-1 mr-1 shadow-xl"/>
                <Dropdown
                  options={YEAR_LABELS} 
                  setValue={this.setEndYear}
                  buttonStyle="flex justify-center border-2 border-black rounded-lg w-20 p-3 mr-1 hover:bg-gray-300"
                  menuStyle="absolute z-40 bg-white max-h-24 overflow-auto border-l-2 border-r-2 border-b-2 border-black rounded-lg w-20 p-1 mr-1 shadow-xl"/>
              </div>
            </div>
            {this.state.error ? <label className="font-semibold text-center text-red-400">* Fields Cannot Be Blank</label> : null}
            <button 
              className= "mt-4 inline-flex items-center justify-center px-8 py-2 rounded-md bg-primary hover:bg-accent w-full h-12 text-base font-small leading-6 text-white"
              onClick={this.checkErrors}>Submit</button>
          </div>
        </div>
      </>
    )
  }
}