import React, { Component } from 'react';
import TabBar from './TabBar';
import TabDisplayWindow from './TabDisplayWindow';
import DateSelection from './DateSelection';
import axios from 'axios';

import { GRAPH_TEST_DATA } from '../resources/TestData';
import { TABS, MONTH_CONVERSION } from '../resources/Config';
import { DATA_KEY, AUTH_TOKEN_KEY, GET_AGGREGATE_DATA_PATH, GET_AGGREGATE_DATA_TITLE } from '../resources/Vault';
import { aggregateDates, getDataByDate } from '../resources/url_construction';
import { ErrorToast }  from './helperComponents/ErrorToast';



/*
 * Patient Data Window Component
 * Component which houses the tab bar and the display window which
 *   houses the graphs we want to display
 * 
*/
class PatientDataWindow extends Component {
  constructor(props) {
    super(props);
    this.state = {
      data: [],
      dates: [],
      aggData: '',
      tabs: TABS,
      activeTab: '0',
      showAPIErrorToast: false
    }
    this.updateWindow = this.updateWindow.bind(this);
    this.formatDates = this.formatDates.bind(this);
    this.sortDates = this.sortDates.bind(this);
    this.formatForDropdown = this.formatForDropdown.bind(this);
    this.setDate = this.setDate.bind(this);
    this.updateAggData = this.updateAggData.bind(this);
    this.updatePatientData = this.updatePatientData.bind(this);
  }

  // Function which updates the active tab to send into the display window
  updateWindow(tabID) {
    this.setState({
      ...this.state,
      activeTab: tabID
    })
  }

  /**
   * Turns date strings into Date objects
   * @param {Array<Object>} data Patient data 
   * @returns Patient data with objectified dates
   */
  formatDates(data) {
    for (var i=0; i<data.length; i++) {
      data[i].date = Date.parse(data[i].date);
    }
    return data;
  }

  /**
   * Sorts dates by most recent
   * @param {Array<string>} dates Array of dates 
   * @returns Sorted array of dates
   */
  sortDates(dates) {
    dates.sort(function (a, b) {
      if (a < b) {
        return 1;
      } else if (a === b) {
        return 0;
      } else {
        return -1;
      }
  });
   return dates;
  }

  /**
   * Formats exercise data from the database and sorts it
   * @param {Array<Object>} data Exercise data
   * @returns Properly formatted and sorted data
   */
  formatExerciseData(data) {
    data = this.formatDates(data);
    data.sort(function (a, b) {
      if (a.date < b.date) {
        return -1;
      } else if (a.date === b.date) {
        return 0;
      } else {
        return 1;
      }
    });
    return data;
  }

  /**
   * Creates readable date labels for each date given
   * @param {Array<string>} arr Array of agg dates
   * @returns Array of dropdown entries
   */
  formatForDropdown(arr) {
    let dropdownItems = [];
    if (arr.length > 0) {
      arr.forEach(item => {
        let temp = item.split('-');
        let month;
        let year = temp[1];

        // Get text for the month
        month = MONTH_CONVERSION[temp[0]];

        // Create a dropdown entry
        dropdownItems.push({
          value: item,
          label: `${month} ${year}`
        });
      });
    }
    
    return dropdownItems;
  }

  componentDidMount() {
    let dates = [];
    // Get dates
    axios.get(aggregateDates(this.props.pid), {
      headers: {
        "x-access-token": localStorage.getItem(AUTH_TOKEN_KEY)
      }
    }).then((res) => {
      dates = this.sortDates(res.data);
      this.setState({...this.state, dates: dates, activeDate: dates[0]});
      this.updateAggData(dates[0]);
      this.updatePatientData(dates[0]);
    }).catch((err) => {
      console.log(err);
    })
  }

  /**
   * Updates the aggregate data from the backend
   * @param {string} date Date
   */
  updateAggData(date) {
    axios.post(GET_AGGREGATE_DATA_PATH, {
      title: GET_AGGREGATE_DATA_TITLE,
      info: {
        pid: this.props.pid,
        date: date
      }
    },
    {
      headers: {
      "x-access-token": localStorage.getItem(AUTH_TOKEN_KEY)
    }
    }).then((res) => {
      this.setState({...this.state, 
        showAPIErrorToast: false, 
        aggData: res.data});
    })
    .catch(error => {
      console.log(error);
      this.setState({...this.state,showAPIErrorToast: true})
    });
  }

  /**
   * Update the patient data from the backend
   * @param {string} date Date
   */
  updatePatientData(date) {
    axios.get(getDataByDate(this.props.pid, date), {
      headers: {
        "x-access-token": localStorage.getItem(AUTH_TOKEN_KEY)
      }
    }).then((res) => {
      this.setState({...this.state, data: this.formatExerciseData(res.data)});
    }).catch((err) => {
      console.log(err);
    })
  }

  /**
   * Sets the active date and updates data accordingly
   * @param {string} date Date 
   */
  setDate(date) {
    this.updateAggData(date);
    this.updatePatientData(date);
  }

  render() {
    return(
      <div className="w-full h-full pb-8">
        <div className="w-full px-10">
          <TabBar tabs={this.state.tabs} updateWindow={this.updateWindow}/>
          <DateSelection dates={this.formatForDropdown(this.state.dates)} setDate={this.setDate} />
          <TabDisplayWindow tabID={this.state.activeTab} data={this.state.data} aggData={this.state.aggData} pid={this.props.pid}/>
        </div>
        <div className="absolute bottom-0 right-0 mr-10 mb-6">
              {this.state.showAPIErrorToast ? <ErrorToast message={"Unable to retrieve aggregation data"} visible={this.state.showAPIErrorToast}/> : null}
        </div>
      </div>
    )
  };
}

export default PatientDataWindow;
