import React, { useEffect } from 'react';
import SignupInput from '../SignupInput'

/**
 * Signup page, displays SignupInput component
 * @returns JSX to render signup page
 */
function Signup() {
  
  useEffect(() => {
    document.title = "Signup | Patient Management Portall"
  }, [])
  
  return (
    <>
      <SignupInput />
    </>
  )
}

export default Signup; 
