import React, { Component } from 'react';
import RemoveUserConfirmation from './modals/RemoveUserConfirmation';
import UpdateUserRole from './modals/UpdateUserRole';
import { ErrorToast }  from './helperComponents/ErrorToast';
import { PopOverMenu } from './helperComponents/PopOverMenu';
import { BiDotsVerticalRounded } from "react-icons/bi";
import { SuccessToast } from './helperComponents/SuccessToast';


/**
 * Component for showing user information
 */
class UserSimple extends Component {
  constructor(props) {
    super(props);
    /**
     * UserSimple component state
     * @type {Object}
     * @property {string} firstName First name
     * @property {string} lastName Last name
     * @property {string} email Email
     * @property {boolean} showModal Modal showing
     * @property {boolean} changes Have changes been made
     * @property {string} displayPermission User Permissions
     * @property {boolean} ownerChecked Owner permission
     * @property {boolean} adminChecked Admin permission
     * @property {boolean} userChecked User permission
     * @property {Object} userPermission User permission from localStorage
     */
    this.state = {
      firstName: this.props.firstName,
      lastName: this.props.lastName,
      email: this.props.email,
      showModal: false,
      changes: false,
      displayPermission: this.props.permission,
      userPermission: JSON.parse(localStorage.getItem('info')).permission,
      userPermissionErrorToast: false,
      showEditUserMenu: false,
      showEditPermissionsModal: false,
      userPermissionSuccessToast: false,
    };
    this.handleOnCloseRemoveUser = this.handleOnCloseRemoveUser.bind(this);
    this.handleOnExitRemoveUser = this.handleOnExitRemoveUser.bind(this);
    this.handleRemoveUser = this.handleRemoveUser.bind(this);
    this.handleCloseEditUserMenu = this.handleCloseEditUserMenu.bind(this);
    this.handleCloseEditPermissionsModal = this.handleCloseEditPermissionsModal.bind(this);
    this.showEditUserPermissionsModal = this.showEditUserPermissionsModal.bind(this);
    this.handleUpdateUserPermissions = this.handleUpdateUserPermissions.bind(this);
    this.showRemoveUserModal = this.showRemoveUserModal.bind(this);
  };

  // Function which closes edit user menu
  handleCloseEditUserMenu() {
    this.setState({
      ...this.state,
      showEditUserMenu: false
    })
  }

  /**
   * Removes a user from the list by email
   * @param {string} email Email
   */
  handleRemoveUser (email) { 
    // Remove the API call and we will only get here if user removal completed successfully
    this.props.removeUser(email);
      this.setState({
        ...this.state,
        showModal: false,
      });
  }

  /**
   * Close modal window
   */
  handleOnExitRemoveUser () { 
    this.setState({
      ...this.state,
      showModal: false,
    });
  }

  /**
   * Update permission level
   * @param {string} permission Permission level
   */
  handleOnCloseRemoveUser (permission) { 
    this.setState({
      ...this.state,
      showModal: false,
      permission: permission
    });
    this.props.updatePermission(permission, this.state.email);
  }  
  // Function which shows the remove user confirmation modal
  showRemoveUserModal() {
    this.setState({...this.state,showModal: true})
  }

  // Function which shows the edit user permissions modal
  showEditUserPermissionsModal() {
    this.setState({
      ...this.state,
      showEditPermissionsModal: true

    })
  }

  // Function which closes the edit user permissions modal
  handleCloseEditPermissionsModal() {
    this.setState({
      ...this.state,
      showEditPermissionsModal: false
    })
  }

  // Function which updates the user permissions after they've been updated in the database
  handleUpdateUserPermissions(perm) {
    this.props.updatePermission();
    this.setState({
      ...this.state,
      displayPermission: perm,
      ownerChecked: perm === "owner", 
      adminChecked: perm === "admin",
      userChecked: perm === "user",
      showEditPermissionsModal: false
    });
  }

  render() {
    return (
      <>
        {/* New Structure */}
        <div className="w-full flex flex-row py-4 hover:bg-[#FFFFF0]">
          <div className="w-1/3 ml-4">
            <label className="text-lg">{this.props.firstName + " " + this.props.lastName}</label>
          </div>
          <div className="w-1/3">
          
            {this.state.displayPermission === 'owner' && 
              <div className="w-20 bg-[#14ACAA] rounded-xl p-2 flex items-center justify-center">
                <label className="text-lg text-white">{this.state.displayPermission}</label>
              </div>
            }
            {this.state.displayPermission === 'admin' && 
              <div className="w-20 bg-[#F5A623] rounded-xl p-2 flex items-center justify-center">
                <label className="text-lg text-white">{this.state.displayPermission}</label>
              </div>
            }
            {this.state.displayPermission === 'user' && 
              <div className="w-20 bg-[#04223A] rounded-xl p-2 flex items-center justify-center">
                <label className="text-lg text-white">{this.state.displayPermission}</label>
              </div>
            }
            </div>
          <div className="w-1/3 flex flex-row justify-between">
            <label className="text-lg">{this.state.email}</label>
            <BiDotsVerticalRounded 
              className="mt-2 mr-2 cursor-pointer"
              onClick={() => this.setState({
                ...this.state,
                showEditUserMenu: true
              })}
            />
          </div>
          <PopOverMenu
            showMenu={this.state.showEditUserMenu}
            handleOnClose={this.handleCloseEditUserMenu}
            showEditPermissionsModal={this.showEditUserPermissionsModal}
            showRemoveUserModal={this.showRemoveUserModal}
          />
        </div>
        {/* End New Structure */}

        <RemoveUserConfirmation
            visible={this.state.showModal}
            handleRemove = {this.handleRemoveUser}
            handleOnExit = {this.handleOnExitRemoveUser}
            handleOnClose = {this.handleOnCloseRemoveUser}
            email = {this.state.email}/>

        <UpdateUserRole
          visible={this.state.showEditPermissionsModal}
          email = {this.state.email}
          name = {this.state.firstName + " " + this.state.lastName}
          permission={this.state.displayPermission}
          onExit={this.handleCloseEditPermissionsModal}
          updatePermissions={this.handleUpdateUserPermissions}
        />

        {/* TODO: Move the error toast into the modals */}
        {/* <div className="absolute bottom-0 right-0 mr-10 mb-6">
          {this.state.userPermissionErrorToast ? <ErrorToast message={"There was an issue changing user permissions"} visible={this.state.userPermissionErrorToast}/> : null}
        </div> */}
      </>
    )
  }
}

export default UserSimple;
