import { Component } from "react";
import axios from "axios";
import { withRouter } from "react-router";
import { VERIFY_2FA_PATH, AUTH_TOKEN_KEY, INFO_KEY, PORTAL_PAGE_PATH, LOGOUT_PATH } from "../resources/Vault";

class Verify2fa extends Component {
    constructor() {
        super();
        this.params = new URLSearchParams(window.location.search);
        this.state = {
            duo_state: this.params.get("state"),
            duo_code: this.params.get("duo_code")
        }
    }

    componentDidMount() {
        axios.get(VERIFY_2FA_PATH,{
            withCredentials: true,
            params: {
                state: this.state.duo_state,
                duo_code: this.state.duo_code
            }
        })
            .then((response) => {
                localStorage.setItem(AUTH_TOKEN_KEY, response.data.auth_token);
                localStorage.setItem(INFO_KEY, JSON.stringify(response.data.acc));
                this.props.history.push({pathname: PORTAL_PAGE_PATH});
            })
            .catch((err) => {
                console.log(err);
                this.props.history.push({pathname: LOGOUT_PATH});
                // TODO: Propogate 2fa error state to login page
            })
    }

    render () {
        return null;
    }
}

export default withRouter(Verify2fa);
