import axios from 'axios';
import React, { Component } from 'react';
import PatientSimple from '../PatientSimple';
import { AddPatient } from '../modals/AddPatient';
import { withRouter } from 'react-router';
import CMS from '../CMS';
import { SuccessToast } from '../helperComponents/SuccessToast';
import { PORTAL_TEST_DATA } from '../../resources/TestData';
import { GET_PATIENTS_PATH, GET_ACTIVE_SESSIONS_PATH, INFO_KEY, PORTAL_PAGE_PATH, LOGIN_PAGE_PATH, AUTH_TOKEN_KEY, PERMISSIONS } from '../../resources/Vault';
import { ComplianceTriageObject } from '../ComplianceTriageObject';
import { getResultList } from '../../utils/get-search-results';
import { addSessionData } from '../../utils/add-patient-sessions';
import { cardio } from 'ldrs';

/**
 * Portal main class
 */
class Portal extends Component {
  
  constructor() {
    super();
    this.state = {
      patients: [],
      originalPatientSet: [],
      info: JSON.parse(localStorage.getItem(INFO_KEY)),
      showModal: false,
      showAddPatientSuccessToast: false,
      complianceData: [],
    };
    this.handleOnClose = this.handleOnClose.bind(this);
    this.handleOnExit = this.handleOnExit.bind(this);
    this.getSearchResultList = this.getSearchResultList.bind(this);
    // Register the loading animation
    cardio.register();
  };

  /**
   * Used to accept information from the AddPatient modal
   * @param {Object} patient Patient information
   */
  handleOnClose (patient) {
    // Add the newly added patient to the list of patients
    this.setState({
      ...this.state,
      showModal: false,
      patients: [...this.state.patients, patient],
      originalPatientSet: [...this.state.originalPatientSet, patient],
      showAddPatientSuccessToast: true
    });
  };

  /**
   * Sets page title, checks auth, check for patient being removed
   */
  componentWillMount() {
    sessionStorage.clear();
    document.title = "Portal | Patient Management Portal"

    const getPatients = axios.get(GET_PATIENTS_PATH, {
      headers: {
        "x-access-token": localStorage.getItem(AUTH_TOKEN_KEY)
      }
    });
    
    const getActiveSessions = axios.get(GET_ACTIVE_SESSIONS_PATH, {
      headers: {
        "x-access-token": localStorage.getItem(AUTH_TOKEN_KEY)
      }
    });

    // Make sure both the API calls are completed before adding sessions to patients
    Promise.all([getPatients, getActiveSessions])
      .then(([patientsResponse, complianceResponse]) => {
        // Check authentication from the patients response
        if (!patientsResponse.data.auth) {
          this.props.history.push({ pathname: LOGIN_PAGE_PATH });
          return;
        }

        // Make sure both APIs have completed before adding the session data to the patients
        let patients = addSessionData(patientsResponse.data.patients, complianceResponse.data);
        // Update state with the data from both responses
        this.setState({
          info: JSON.parse(localStorage.getItem(INFO_KEY)),
          patients: patients,
          originalPatientSet: patients,
          complianceData: complianceResponse.data,
        });
      }).catch(error => {
        console.log(error);
        this.props.history.push({ pathname: LOGIN_PAGE_PATH });
      });

    // Check to see if a patient is getting removed
    if(this.props.match.params.pid) {
      var temp = this.props.match.params.pid;
      var filtered = this.state.patients.filter(function(item) { 
       return item.pid !== temp;  
      });
      this.setState({patients: filtered});
      this.props.history.replace({pathname: PORTAL_PAGE_PATH});
    }
  };

  /**
   * Closes the AddPatient modal
   */
  handleOnExit () {
    this.setState({...this.state,showModal: false});
  };

  getSearchResultList(searchTerm) {
    this.setState({...this.state, patients: getResultList(this.state.originalPatientSet, searchTerm)});
  }

  render() {
    if (this.state.info.permission == PERMISSIONS.OWNER || this.state.info.permission == PERMISSIONS.ADMIN || this.state.info.permission == PERMISSIONS.USER) {
    return (
      <section className="flex flex-row h-screen w-screen pb-32 px-4">
        {/* Section for the Compliance Triage */}
        <section className="flex flex-col w-1/5 mr-2 mt-4 pb-4">
          <label className="w-full text-center text-3xl font-bold pb-2 border-b-4 border-[#D3D3D3] shadow-lg bg-[#E5E4E2] rounded">
            Compliance Triage
          </label>

          {/* Compliance Container */}
          <div className="mt-2 overflow-auto px-2">
            {!this.state.complianceData.length == 0 ?
              this.state.complianceData.map(block => <ComplianceTriageObject {...block} />)
              : 
              <div className="w-full flex justify-center mt-10">
                <l-cardio size="80" stroke="6" speed="2" color="black" />
              </div>
            }
          </div>
        </section>

        <section className="h-screen border border-black">
          
        </section>

        {/* Section for the patient information */}
        <section className="flex flex-col w-4/5 ml-2 mt-4 mr-4">
          <div className="bg-[#E5E4E2] rounded">
            <div className="flex justify-center">
              <label className="text-3xl font-bold">
                Patient View
              </label>
            </div>

            {/* Info / Search / Add Patient Section */}
            <div className="w-full flex flex-row mt-8 px-4">
              <div className="w-1/2 flex flex-col">
                {/* User Management and info block */}
                <label className="text-2xl font-bold">Patient Management</label>
                <label className="text-md">View patients at a glance, or add a new patient</label>
              </div>
              <div className="w-1/2 flex flex-row-reverse">
                {/* Add user button */}
                { this.state.info.permission != 'user' ? 
                  <button
                    class="w-2/5 h-full  rounded-md bg-primary hover:bg-accent text-white"
                    onClick={() => this.setState({...this.state, showModal: true})}>
                    Add Patient
                  </button>
                  : null }
                {/* Search Bar */}
                <input
                  type="Search"
                  id="Search"
                  className=" text-xl placeholder-gray-400 bg-gray-200 rounded-lg focus:outline-none focus:ring-4 focus:ring-blue-600 focus:ring-opacity-50 mr-2"
                  placeholder="Search"
                  autocomplete="off"
                  readonly
                  onChange={event => this.getSearchResultList(event.target.value)} />
              </div>
            </div>
            
            {/* Column Descriptions */}
            <div>
              <div className="w-full flex flex-row border-b-4 border-[#D3D3D3] mt-4 shadow-lg">
                <div className="w-1/6 text-center">
                  <label className="text-lg">Name</label>
                </div>
                <div className="w-1/6 text-center">
                  <label className="text-lg">Date of Birth</label>
                </div>
                <div className="w-1/6 text-center">
                  <label className="text-lg">Sex</label>
                </div>
                <div className="w-1/6 text-center">
                  <label className="text-lg">Weight</label>
                </div>
                <div className="w-1/6 text-center">
                  <label className="text-lg">Height</label>
                </div>
                <div className="w-1/6 text-center">
                  <label className="text-lg">Session Status</label>
                </div>
              </div>
            </div>
          </div>
          
          {/* Patient View Section */}
          <div className="rounded-md overflow-auto">
            <div className="flex flex-col divide-y-2">
              { this.state.patients.length == 0 ? 
                  <div className="w-full flex justify-center mt-10">
                    <l-cardio size="80" stroke="6" speed="2" color="black" />
                  </div>
                : this.state.patients.map(block => <PatientSimple {...block} />)
              }
            </div>
          </div>
        </section>

        <AddPatient
          visible={this.state.showModal}
          handleOnClose = {this.handleOnClose}
          addPatient = {this.addPatient}
          handleOnExit = {this.handleOnExit}
        />
      </section>
    )
    } else {
      return(
        <p>Contact your system administrator.</p> 
      )
    }
  }
}

export default withRouter(Portal);
