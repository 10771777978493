import React, { Component } from 'react';
import axios from 'axios';
import { UPDATE_ORG_NAME_PATH, AUTH_TOKEN_KEY } from '../../resources/Vault';
import { ErrorToast }  from '../helperComponents/ErrorToast';
import { SuccessToast } from '../helperComponents/SuccessToast';


export class EditOrg extends Component {
  constructor(props) {
    super(props);
    this.state = { 
      orgName: this.props.orgName,
      error: "",
      showAPIErrorToast: false,
      showAPISuccessToast: false,
      opacity: false,
    };
    this.onSubmit = this.onSubmit.bind(this);
    this.close = this.close.bind(this);
  };

  /**
   * Function which calls the backend and submits the modals
   * We call the handle on close method of the parent component and close the modal
   */
  onSubmit () {
    this.setState({...this.state, opacity: true});
    axios.put(UPDATE_ORG_NAME_PATH, {
      info: {
        "orgName": this.state.orgName
      }
    },
    {
      headers: {
        "x-access-token": localStorage.getItem(AUTH_TOKEN_KEY)
      }
    }).then((res) => {
      this.setState({...this.state, opacity: false});
      this.props.handleOnClose(this.state.orgName);
      this.setState({
        ...this.state,
        showAPIErrorToast: false,
        showAPISuccessToast: true
      });
    }).catch((err) => {
      this.setState({
        ...this.state,
        showAPIErrorToast:true,
        showAPISuccessToast: false,
        opacity: false
      });
      console.log(err);
    });
  };

  /**
   * Function which closes the modal window
   * After closing the window, we reset state to default
   */
  close () {
    this.setState({
      ...this.state,
      showAPIErrorToast: false,
      showAPISuccessToast: false,
    });
    this.props.handleOnExit();
  }

  render() {
    if (!this.props.visible) return null;
    return (
      <>
        <div class={"fixed mt-10 inset-0 bg-black bg-opacity-30 backdrop-blur-sm flex justify-center items-center " + (this.state.opacity ? "opacity-50 pointer-events-none" : "opacity-100")}>
          <div class="bg-white p-4 rounded">
            <div class="flex justify-center">
              <h1 
                className="font-semibold text-center text-xl text-gray-700 mx-16"
                >
                Edit Org Name
              </h1>
              <button 
                className="font-semibold text-center text-xl text-gray-700 w-7 rounded-xl hover:bg-red-400"
                onClick={() => this.close()}>
                X
              </button>
            </div>

            <div className="relative">
              <label className="font-medium text-gray-900">
                Org Name
              </label>
              <input
                onChange={event => this.setState({...this.state,orgName:event.target.value})}
                className="block w-full px-4 py-4 mt-2 text-xl placeholder-gray-400 bg-gray-200 rounded-lg focus:outline-none focus:ring-4 focus:ring-blue-600 focus:ring-opacity-50"
                placeholder={this.props.orgName}
              />
            </div>
            <label className="text-red-500">{this.state.error !== "" ? this.state.error : ""}</label>
            <div className="flex flex-row items-start space-x-4">
            <button 
                class= "mt-4 mx-2 inline-flex items-center justify-center px-8 py-2 rounded-md bg-primary hover:bg-accent w-5/12 h-12 text-base font-small leading-6 text-white"
                onClick={() => this.onSubmit()}>
                Save
            </button>
            <button 
                class= "mt-4 mx-2 inline-flex items-center justify-center px-8 py-2 rounded-md bg-primary hover:bg-accent w-5/12 h-12 text-base font-small leading-6 text-white"
                onClick={() => this.close()}>
                Cancel
            </button>
            </div>
          </div>
          <div className="absolute bottom-0 right-0 mr-10 mb-6">
            {this.state.showAPIErrorToast ? <ErrorToast message={"There was an issue editing your organization"} visible={this.state.showAPIErrorToast}/> : null}
            {this.state.showAPISuccessToast ? <SuccessToast message={"Organization saved"} visible={this.state.showAPISuccessToast}/> : null}
          </div>
        </div>
      </>
    )
  }
}
