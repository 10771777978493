import React, { Component } from "react";
import { withRouter } from 'react-router';
import axios from 'axios';
import { REMOVE_PATIENT_PATH, REMOVE_PATIENT_TITLE, AUTH_TOKEN_KEY, DATA_KEY, REMOVE_PATH } from '../../resources/Vault';
import { ErrorToast }  from '../helperComponents/ErrorToast';
import { SuccessToast } from "../helperComponents/SuccessToast";

class RemovePatient extends Component{
  
  constructor(props) {
    super(props);
    this.state = {
      password:"",
      error: false,
      incorrectPassword: false,
      showAPIErrorToast: false,
      showAPISuccessToast: false,
      opacity: false,
    };
    this.handleKeypress = this.handleKeypress.bind(this)
    this.handleOnClose = this.handleOnClose.bind(this)
    this.checkErrors = this.checkErrors.bind(this)
    this.onSubmit = this.onSubmit.bind(this)
    this.close = this.close.bind(this)
  }
  handleOnClose () {
    this.setState({...this.state,showModal: false})
  };
  
  /**
   * Function which checks for errors and then closes the modal
   * If we have no errors then submit, else show error message
   */
  checkErrors () {
    if(this.state.password == ""){
      this.setState({...this.state,error:true})
    } else {
      this.onSubmit()
    }
  }
  handleKeypress(e) {
    if (e.charCode === 13) {
      this.checkErrors();
    }
  };

  /**
   * Function which calls the BE and  submits the modals
   * We call the handle on close method of the parent component and close the modal
   */
  onSubmit () {
    this.setState({...this.state, opacity: true});
    axios.delete(REMOVE_PATIENT_PATH, {
      headers: {
        "x-access-token": localStorage.getItem(AUTH_TOKEN_KEY)
      },
      data: {
        title: REMOVE_PATIENT_TITLE,
        info: {
          pid: this.props.pid,
          password: this.state.password
        }
      }
    }).then((res) => {
      this.setState({...this.state, opacity: false});
      this.props.handleOnClose(this.state)
      this.setState({
        ...this.state,
        password: "",
        error: false,
        showAPIErrorToast: false,
        showAPISuccessToast: true,
      })
      sessionStorage.removeItem(DATA_KEY);
      var path = REMOVE_PATH + this.props.pid.toString();
      this.props.history.replace({pathname: path})
    }).catch((err) => {
      console.log(err);
      this.setState({
        ...this.state, 
        opacity: false,
        password: "",
        error: false,
        incorrectPassword: true,
        showAPIErrorToast: true,
        showAPISuccessToast: false,
      })
    });
  };

  /**
   * Function which closes the modal window
   * After closing the window, we reset state to default
   */
  close () {
    this.props.handleOnExitRemovePatient();
    this.setState({
      password: "",
      error: false,
      incorrectPassword: false
    })
  }

  render(){
    if (!this.props.visible) return null;
    return (
      <>
        <div className={"fixed mt-10 inset-0 bg-black bg-opacity-30 backdrop-blur-sm flex justify-center items-center " + (this.state.opacity ? "opacity-50 pointer-events-none" : "opacity-100")}>
          <div className="bg-white p-4 rounded">
            <div className="flex justify-center">
              <h1 
                className="font-semibold text-center text-xl text-gray-700 mx-16"
                >
                Remove Patient
              </h1>
              <button 
                className="font-semibold text-center text-xl text-gray-700 w-7 rounded-xl hover:bg-red-400"
                onClick={() => this.close()}>
                X
              </button>
            </div>
            <div className="relative">
              <div className="flex justify-center">
                <label className="justify-center font-medium text-gray-900">
                  Enter your password to confirm
                </label>
              </div>
              {this.state.error ?
              <input
                onChange={event => this.setState({...this.state,password:event.target.value})}
                onKeyPress={this.handleKeypress}
                type="password"
                className="block w-full px-4 py-4 mt-2 text-xl placeholder-gray-400 bg-gray-200 rounded-lg ring outline-none ring-4 ring-opacity-50 ring-red-600 focus:outline-none focus:ring-4 focus:ring-blue-600 focus:ring-opacity-50"
                placeholder="Password"
              />
              :
              <input
                onChange={event => this.setState({...this.state,password:event.target.value})}
                onKeyPress={this.handleKeypress}
                type="password"
                className="block w-full px-4 py-4 mt-2 text-xl placeholder-gray-400 bg-gray-200 rounded-lg focus:outline-none focus:ring-4 focus:ring-blue-600 focus:ring-opacity-50"
                placeholder="Password"
              />
              }
              {this.state.incorrectPassword ? <label className="font-semibold text-center text-red-400">* Incorrect Password</label> : null}
            </div>

            <button 
              className= "mt-4 inline-flex items-center justify-center px-8 py-2 rounded-md bg-primary hover:bg-accent w-full h-12 text-base font-small leading-6 text-white"
              onClick={this.checkErrors}>Remove
            </button>
            <div className="absolute bottom-0 right-0 mr-10 mb-6">
              {this.state.showAPIErrorToast ? <ErrorToast message={"There was an issue removing this patient"} visible={this.state.showAPIErrorToast}/> : null}
              {this.state.showAPISuccessToast ? <SuccessToast message={"Patient removed"} visible={this.state.showAPISuccessToast}/> : null}
            </div>
          </div>
        </div>
      </>
    )
  }
}
export default withRouter(RemovePatient);
