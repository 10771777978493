import React, { Component } from "react";
import { UPDATE_USER_PERMISSION_PATH, AUTH_TOKEN_KEY } from '../../resources/Vault';
import { BiX } from "react-icons/bi";
import axios from 'axios';
import { SuccessToast } from "../helperComponents/SuccessToast";
import { ErrorToast }  from '../helperComponents/ErrorToast';

class UpdateUserRole extends Component {

  constructor(props) {
    super(props);
    this.state = {
      error: false,
      opacity: false,
      email: this.props.email,
      displayPermission: this.props.permission,
      ownerChecked: this.props.permission === 'owner', 
      adminChecked: this.props.permission === 'admin',
      userChecked: this.props.permission === 'user',
      userPermission: JSON.parse(localStorage.getItem('info')).permission,
      changes: false,
      showAPIErrorToast: false,
      showAPISuccessToast: false, 
    };
  }

  /**
   * Function which closes the modal window without making any updates
   * to the database
   */
  close() {
    this.props.onExit();

    this.setState({
      ...this.state,
      ownerChecked: this.props.permission == "owner", 
      adminChecked: this.props.permission == "admin",
      userChecked: this.props.permission == "user",
      changes: false,
      password: "",
      error: false,
      incorrectPassword: false,
      showAPIErrorToast: false,
    })
  }

  /**
   * Converts checkbox to value
   * @returns {string} Permission level
   */
  checkPermissionBox() {
    if(this.state.ownerChecked) {
      return "owner";
    } else if(this.state.adminChecked) {
      return "admin";
    } else if(this.state.userChecked) {
      return "user";
    } else {
      return false; //ERROR
    }
  };

  /**
   * Update permissions using backend API
   */
  submitPermissionChanges() {
    let perm = this.checkPermissionBox();
    //Check for error
    if(!perm) { return; };
    // Update permissions
    axios.put(UPDATE_USER_PERMISSION_PATH, {
      info: {
        "email" : this.state.email,
        "permissions": perm
      }
    },
    {
      headers: {
        "x-access-token": localStorage.getItem(AUTH_TOKEN_KEY)
      }
    }).then((res) => {
      this.props.updatePermissions(res.data.permissions);
      this.setState({
        ...this.state,
        showAPISuccessToast: true,
        showAPIErrorToast: false,
      });
    }).catch((err) => {
      console.log(err);
      this.setState({
        ...this.state,
        showAPIErrorToast: true,
        showAPISuccessToast: false,
      })
    });
  }

  render() {
    if (!this.props.visible) return null;
    return (
        <>
          <div className={"fixed mt-10 inset-0 bg-black bg-opacity-30 backdrop-blur-lg flex justify-center items-center " + (this.state.opacity ? "opacity-50 pointer-events-none" : "opacity-100")}>
            <div className="bg-white px-6 pb-6 rounded w-1/6">
              {/* Modal Title */}
              <div className="sticky top-0 z-40 flex justify-end bg-white border-b-2 h-12 p-2 -mx-6">
                <h1 className="font-semibold text-center text-xl text-gray-700">
                  Change User Role
                </h1>
                <BiX  
                  className="font-semibold text-center text-xl text-gray-700 h-full w-7 ml-16 rounded-md hover:bg-red-400 cursor-pointer"
                  onClick={() => this.close()}>
                </BiX >
              </div>
              
              {/* Begin Content */}
              <div className=" mt-3 relative">
                <div className="flex flex-col">
                  <div className="flex flex-row">
                    <label className="text-lg font-semibold text-gray-900 mr-2">Name:</label>
                    <label className="text-lg text-gray-900">{this.props.name}</label>
                  </div>
                  <div className="flex flex-row mb-6">
                    <label className="text-lg font-semibold text-gray-900 mr-2">Email:</label>
                    <label className="text-lg text-gray-900">{this.props.email}</label>
                  </div>
                </div>
                <label className="text-lg font-semibold text-gray-900">
                  Permission
                </label>
                {/* Owner Checkbox */}
                <div className="flex items-center">
                  <label>
                    <input 
                      type="checkbox"
                      checked={this.state.ownerChecked && !this.state.adminChecked && !this.state.userChecked}
                      disabled={this.state.userPermission === "admin" || this.state.userPermission === "user"}
                      onChange={event => this.setState({...this.state,
                        adminChecked:false,
                        ownerChecked:true,
                        userChecked:false,
                        changes: true})} />
                    Owner
                  </label>
                </div>

                {/* Admin Checkbox */}
                <div className="flex items-center">
                  <label>
                    <input 
                      type="checkbox"
                      checked={this.state.adminChecked && !this.state.ownerChecked && !this.state.userChecked}
                      disabled={
                        (this.props.permission === "owner" && this.state.userPermission !== "owner") ||
                        this.state.userPermission === "user"
                      }
                      onChange={event => this.setState({...this.state,
                        adminChecked:true,
                        ownerChecked:false,
                        userChecked:false,
                        changes: true})} />
                    Admin
                  </label>
                </div>

                {/* User Checkbox */}
                <div className="flex items-center">
                <label>
                    <input 
                      type="checkbox"
                      checked={this.state.userChecked && !this.state.ownerChecked && !this.state.adminChecked}
                      disabled={
                        this.state.userPermission === "user" ||
                        this.props.permission === "owner" ||
                        (this.props.permission === "admin" && this.state.userPermission === "admin") ||
                        (this.props.permission === "owner" && this.state.userPermission === "admin")
                      }
                      onChange={event => this.setState({...this.state,
                        adminChecked:false,
                        ownerChecked:false,
                        userChecked:true, 
                        changes: true})} />
                    User
                  </label>
                </div>
              </div>

              {/* Confirm / Cancel Buttons */}
              <div>
                {this.state.changes ?
                  <div className="flex items-stretch mb-2">
                    <button 
                      className= "mt-4 mx-2 inline-flex items-center justify-center px-8 py-2 rounded-md bg-primary hover:bg-accent w-5/12 h-12 text-base font-small leading-6 text-white"
                      onClick={() => this.submitPermissionChanges()}>
                        Confirm
                    </button>
                    <button 
                      className= "mt-4 mx-2 inline-flex items-center justify-center px-8 py-2 rounded-md bg-primary hover:bg-accent w-5/12 h-12 text-base font-small leading-6 text-white"
                      onClick={() => this.close()}>
                        Cancel
                    </button>
                  </div>
                :
                  null}
              </div>
              
            </div>
            <div className="absolute bottom-0 right-0 mr-10 mb-6">
              {this.state.showAPIErrorToast ? <ErrorToast message={"There was an issue updating this role"} visible={this.state.showAPIErrorToast}/> : null}
              {this.state.showAPISuccessToast ? <SuccessToast message={"User permission updated"} visible={this.state.showAPISuccessToast}/> : null}
            </div>
          </div>
        </>
      )
  }
}
export default (UpdateUserRole);