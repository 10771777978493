import React, { Component } from 'react';
import { ComplianceObject } from './ComplianceObject';
import { RespiratoryObject } from './RespiratoryObject';
import { MSKObject } from './MSKObject';
import { ActivityObject } from './ActivityObject';

export class CMSColumn extends Component {
  constructor(props) {
    super(props);
    /* 
    * @param componentType: String value which controls which type of column we render
    * @param data: object containing the data to be rendered in each column
    */
    this.state = {
        componentType: this.props.componentType,
        render: true,
        title: null
    };
  }

  // on component mount, check the component type against a list of constants
  // Then set the information that we will need to dispolay
  componentDidMount() {
    if(this.state.componentType === "complianceTriage") {
      this.setState({...this.state,title: "Compliance Triage"})
    }
    if(this.state.componentType === "respiratory") {
      this.setState({...this.state,title: "Respiratory Reporting"})
    }
    if(this.state.componentType === "msk") {
      this.setState({...this.state,title: "MSK Reporting"})
    }
    if(this.state.componentType === "activityMedication") {
      this.setState({...this.state,title: "Activity & Medication"})
    }
    if(this.state.componentType === null) {
      // We dont have a component type so render nothing
      this.setState({...this.state,render: false})
    }
  }

  render() {
    if(this.state.render) {
      return (
        <>
          <div className="w-1/4 mr-3 ml-3">
          <label className="flex justify-center font-sans text-2xl font-bold text-primary my-4">
            {this.state.title}
          </label>
          <div className="basis-full max-h-screen overflow-auto px-3 py-3 h-5/6 bg-white rounded-3xl w-full min-w-min">
            {this.state.title === "Compliance Triage" ?
              this.props.data.map(block => <ComplianceObject {...block} />)
              : null
            }
            {this.state.title === "Respiratory Reporting" ? 
              this.props.data.map(block => <RespiratoryObject {...block} />)
              : null
            }
            {this.state.title === "MSK Reporting" ? 
              this.props.data.map(block => <MSKObject {...block} />)
              : null
            }
            {this.state.title === "Activity & Medication" ? 
              this.props.data.map(block => <ActivityObject {...block} />)
              : null
            }
          </div>
          </div>
        </>
      )
    } else {
      return (null)
    }
  }
}