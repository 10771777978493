import React, { Component } from "react";
import { withRouter } from 'react-router';
import Logo from "./logo";
import axios from 'axios';
import { DASHBOARD_PAGE_PATH, LOGIN_PAGE_PATH, PORTAL_PAGE_PATH } from "../resources/Vault";
import { AUTH_TOKEN_KEY, IS_AUTH_PATH, LOGOUT_PATH } from '../resources/Vault'

/**
 * Portal Menubar
 */
class Menubar extends Component {
  constructor(props) {
    super(props);
    /**
     * Menubar component state
     * @type {Object}
     * @property {boolean} auth Authentication status
     */
    this.state = {
      auth: false
    };
    this.openInNewTab = this.openInNewTab.bind(this);
    this.updateAuth = this.updateAuth.bind(this);
    this.logOut = this.logOut.bind(this);
  };

  /**
   * Opens a given URL in a new tab
   * @param {string} url URL to open in new tab
   */
  openInNewTab(url) {
    window.open(url, '_blank', 'noopener,noreferrer');
  };

  logOut() {
    this.setState({...this.state,auth:false});
    this.props.history.push({pathname: LOGOUT_PATH});
  }
  
  /**
   * Gets authentication
   */
  componentWillMount() {
    // TODO: Noticed that sometimes a bad auth token is still saved in our local storage
    // This causes this function to error out on line 34, need to fix logic here
    let token = localStorage.getItem(AUTH_TOKEN_KEY);
    if(token != null) {
      axios.get(IS_AUTH_PATH, {
        headers: {
          "x-access-token": localStorage.getItem(AUTH_TOKEN_KEY)
        }
      }).then((res) => {
        this.setState({...this.state,auth: res.data.auth});
      }).catch((err) => {
        this.setState({...this.state, auth: false});
      });
    } else {
      this.setState({...this.state, auth: false});
    }
  };

  /**
   * Updates authentication state
   * @param {boolean} auth Authentication state
   */
  updateAuth(auth) {
    this.setState({...this.state,auth: auth});
  }

  /**
   * Checks authentication state
   * @param {Object} prevProps Previous props
   * @param {Object} prevState Previous state
   */
  componentDidUpdate(prevProps, prevState) {
    let token = localStorage.getItem(AUTH_TOKEN_KEY);
    if(token != null) {
      axios.get(IS_AUTH_PATH, {
        headers: {
          "x-access-token": localStorage.getItem(AUTH_TOKEN_KEY)
        }
      }).then((res) => {
        if (res.data.auth != this.state.auth) {
          this.updateAuth(res.data.auth);
        }
      }).catch((err) => {
        if (err.auth != this.state.auth) {
          this.updateAuth(err.auth);
        }
      });
    }
  };
  
  render() {
    return (
      <section className="w-full px-8 py-2 text-gray-700 bg-opacity-95 bg-white sticky top-0 z-20 shadow-xl">
        <div className="flex flex-row justify-evenly">
          <div className="ml-3 relative flex flex-col md:flex-row w-1/3 flex-start">
            <Logo></Logo>
          </div>
          <div className="w-1/3 flex justify-center">
            <label className="flex justify-center font-sans text-2xl font-bold text-primary my-4">
              {JSON.parse(localStorage.getItem("info")) ? JSON.parse(localStorage.getItem("info")).orgName : null}
            </label>
          </div>

          <div className="inline-flex items-center w-1/3 space-x-4 lg:justify-end">
            <button
              onClick={() => this.openInNewTab('https://balance3ds.com/')}
              className=" border-2 border-primary rounded-md px-4 py-2 text-base font-medium leading-6 text-gray-600 whitespace-no-wrap transition duration-150 ease-in-out hover:text-background hover:bg-primary"
            >
              Learn More
            </button>
            {this.state.auth ? 
              <button
                onClick={() => this.props.history.push({pathname: DASHBOARD_PAGE_PATH})}
                className="inline-flex items-center justify-center px-4 py-2 text-base font-medium leading-6 text-white whitespace-no-wrap bg-primary border border-transparent rounded-md shadow-sm hover:bg-accent focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-accent transition duration-150 ease-in-out"
              >
                Dashboard
              </button>
              : null
            }
            {this.state.auth ? 
              <button
                onClick={() => this.props.history.push({pathname: PORTAL_PAGE_PATH})}
                className="inline-flex items-center justify-center px-4 py-2 text-base font-medium leading-6 text-white whitespace-no-wrap bg-primary border border-transparent rounded-md shadow-sm hover:bg-accent focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-accent transition duration-150 ease-in-out"
              >
                Portal
              </button>
              : null
            }
            {this.state.auth ?  
              <button
                onClick={() => this.logOut()}
                className="inline-flex items-center justify-center px-4 py-2 text-base font-medium leading-6 text-white whitespace-no-wrap bg-primary border border-transparent rounded-md shadow-sm hover:bg-accent focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-accent transition duration-150 ease-in-out"
              >
                Log Out
              </button>
              : 
              <button
                onClick={() => {!this.state.auth ? this.props.history.push({pathname: LOGIN_PAGE_PATH}) : (this.props.history.push({pathname: LOGOUT_PATH}))}}
                className="inline-flex items-center justify-center px-4 py-2 text-base font-medium leading-6 text-white whitespace-no-wrap bg-primary border border-transparent rounded-md shadow-sm hover:bg-accent focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-accent transition duration-150 ease-in-out"
              >
                Login
              </button>
            }
             
          </div>
        </div>
      </section>
    )
  }
}
export default withRouter(Menubar);
