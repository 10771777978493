import React, { Component } from 'react';


/**
 * Dropdown menu component
 */
export class Dropdown extends Component {
  /**
   * Dropdown constructor
   * @param {Object} props Props
   */
  constructor(props){
    super();
    /**
     * Dropdown component state
     * @type {Object}
     * @property {Object} selectedValue Value and label for the dropdown selection
     * @property {boolean} showMenu Show dropdown options
     */
    this.state = {
      selectedValue: {
        value: props.options[0].value,
        label: props.options[0].label
      },
      showMenu: false
    }
    this.handleInputClick = this.handleInputClick.bind(this);
    this.onItemClick = this.onItemClick.bind(this);
    this.wrapperRef = React.createRef();
    this.handleClickOutside = this.handleClickOutside.bind(this);
  }

  /**
   * Handles opening and closing the dropdown menu
   * @param {event} event Click event
   */
  handleInputClick(event) {
    event.stopPropagation();
    this.setState({...this.state, showMenu: !this.state.showMenu});
  }

  /**
   * Set active option in state and passed function
   * @param {Object} option Set dropdown value
   */
  onItemClick(option) {
    this.setState({
      ...this.state, 
      selectedValue: option,
      showMenu: false
    });
    this.props.setValue(option.value);
  }

  componentDidMount() {
    document.addEventListener("mousedown", this.handleClickOutside);
  }

  componentWillUnmount() {
    document.removeEventListener("mousedown", this.handleClickOutside);
  }

  // Closes the dropdown menu if you click outside of it
  handleClickOutside(event) {
    if (this.wrapperRef && !this.wrapperRef.current.contains(event.target)) {
      this.setState({...this.state,showMenu: false});
    }
  }

  render() {
    return(
      <div ref={this.wrapperRef}>
        <div className="justify-items-center">
          <button onClick={event => this.handleInputClick(event)}className={this.props.buttonStyle}>
            <div className="dropdown-selected-value">{this.state.selectedValue.label}</div>
          </button>
          {this.state.showMenu && (
            <div className={this.props.menuStyle}>
              {this.props.options.map((option) => (
                <button className="flex justify-center w-full hover:bg-gray-200"
                  onClick={() => this.onItemClick(option)}
                  key={option.value}>
                  {option.label}
                </button>
              ))}
            </div>
          )}
        </div>
      </div>
    )
  }
}

