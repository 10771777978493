import React, { Component } from 'react';
import axios from 'axios';
import { withRouter } from 'react-router';
import { CMSColumn } from './CMSComponents/CMSColumn';
import { AUTH_TOKEN_KEY, LOGIN_PAGE_PATH, GET_ACTIVE_SESSIONS_PATH } from '../resources/Vault';


const complianceTestData = [
  {
    firstName: "Simon",
    lastName: "Miller",
    mrn: "0045680",
    inhalerAdherence: "10 / 30 days",
    medicationAdherence: "9 / 30 days",
    peakFlow: "5 / 30 days",
    mskTest: "3 / 30 days",
    monitorSession: false,
    compliant: false
  },
  {
    firstName: "Thomas",
    lastName: "Miller",
    mrn: "0045681",
    inhalerAdherence: "4 / 30 days",
    medicationAdherence: "6 / 30 days",
    peakFlow: "8 / 30 days",
    mskTest: "7 / 30 days",
    monitorSession: true,
    compliant: true
  }
]

const respiratoryTestData = [
  {
    firstName: "Simon",
    lastName: "Miller",
    mrn: "0045680",
    inhalerAdherence: "10 / 30 days",
    breathsPerMinute: "30",
    avgPeakFlow: "600 L/min",
    avgSpo2: "95%",
    monitorSession: false,
    compliant: false
  },
  {
    firstName: "Thomas",
    lastName: "Miller",
    mrn: "0045681",
    inhalerAdherence: "4 / 30 days",
    breathsPerMinute: "30",
    avgPeakFlow: "630 L/min",
    avgSpo2: "92%",
    monitorSession: true,
    compliant: false
  }
] 

const mskTestData = [
    {
      firstName: "Simon",
      lastName: "Miller",
      mrn: "0045680",
      tug: "1.02 m/s / 6.00 s",
      sitToStand: "0.75 m/s / 3.00 s",
      gaitAbnormality: "500 L/min",
      gaitPathology: "Hemiparesis",
      gaitAsymetry: "L: 48% / R: 52%",
      fallRisk: "78%",
      steps: "14078",
      mskAdherence: "10 / 30 days",
      monitorSession: true,
      compliant: true
    },
    {
      firstName: "Thomas",
      lastName: "Miller",
      mrn: "0045681",
      tug: "1.45 m/s / 5.75 s",
      sitToStand: "0.85 m/s / 2.00 s",
      gaitAbnormality: "430 L/min",
      gaitPathology: "Hemiparesis",
      gaitAsymetry: "L: 47% / R: 53%",
      fallRisk: "64%",
      steps: "18135",
      mskAdherence: "15 / 30 days",
      monitorSession: true,
      compliant: false
    }
]
  
const activityTestData = [
  {
      firstName: "Simon",
      lastName: "Miller",
      mrn: "0045680",
      totalReports: "11 / 30 days",
      monitorSession: true,
      compliant: false
    },
    {
      firstName: "Thomas",
      lastName: "Miller",
      mrn: "0045681",
      totalReports: "15 / 30 days",
      monitorSession: true,
      compliant: true
    }
] 

class CMS extends Component {
  constructor(props) {
    super(props);
    this.state = {
      complianceData: [],
      respiratoryData: respiratoryTestData,
      mskData: mskTestData,
      activityData: activityTestData
    };
  };

  componentDidMount() {
    // Get active sessions
    axios.get(GET_ACTIVE_SESSIONS_PATH, {
      headers: {
        "x-access-token": localStorage.getItem(AUTH_TOKEN_KEY)
      }
    }).then((res) => {
      this.setState({complianceData: res.data});
    })
     .catch(error => {
      console.log(error);
      this.props.history.push({pathname: LOGIN_PAGE_PATH});
    });
  }

  render() {
    return(
      <div className="flex flex-row space-x-4 basis-full max-h-screen ml-3 px-3 py-3 h-full bg-gray-200 rounded-3xl w-11/12 min-w-min">
        <CMSColumn
          componentType="complianceTriage" 
          data={this.state.complianceData}/>
        <CMSColumn
          componentType="respiratory"
          data={this.state.respiratoryData}/>
        <CMSColumn
          componentType="msk"
          data={this.state.mskData}/>
        <CMSColumn
          componentType="activityMedication"
          data={this.state.activityData}/>
      </div>
    )
  }
}

export default withRouter(CMS);
