import React from 'react';

/**
 * Displays in tabular window in place of a graph
 * @param {Object} props Text to pass in
 * @returns Formatted JSX with whatever text was passed in
 */
function DataPlaceholder(props) {
    return (
        <>
            <div className="mx-auto max-w-7xl relative z-10">
              <div className="container max-w-lg px-4 py-32 mx-auto md:max-w-none md:text-center">
                <h1 className="text-5xl font-extrabold leading-10 tracking-tight text-center text-primary sm:leading-none md:text-6l lg:text-7l">
                    {props.text}
                </h1>
                
                <div className="flex flex-col items-center md:flex-row mt-12">
                  <span className="relative  inline-flex w-full md:w-auto">
                  </span>
                </div>
              </div>
            </div>
        </>
    )
}

export default DataPlaceholder;
